import React from 'react'
import Header from './Header'
import { Link } from "react-router-dom";
import tick from "../Assets/tick.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import bgPlan from "../Assets/plan-bg.webp";

const pricingArray = [
    {
        planType: "Basic",
        price: "7",
        yearlyPrice: "79",
        mailBoxes: "1",
        messageMonthly: "100",
        overage: "9"
    },
    {
        planType: "Standard",
        price: "14",
        yearlyPrice: "149",
        mailBoxes: "5",
        messageMonthly: "400",
        overage: "8"
    },
    {
        planType: "Plus",
        price: "24",
        yearlyPrice: "249",
        mailBoxes: "15",
        messageMonthly: "1,250",
        overage: "7"
    },
    {
        planType: "Advanced",
        price: "49",
        yearlyPrice: "499",
        mailBoxes: "40",
        messageMonthly: "2,500",
        overage: "6"
    },
    {
        planType: "Ultimate",
        price: "69",
        yearlyPrice: "699",
        mailBoxes: "80",
        messageMonthly: "5,000",
        overage: "5"
    },
]

export default function PricingPage() {
    return (
        <>
            <Header />
            <div className='backgroundImage2'>
                <div className="text-center">
                    <h1 className='text-4xl text-white font-bold pt-5'>
                        PBX (Phone System) Whole-Company Plans
                    </h1>
                    <h5 className='text-sm text-white font-normal max-w-[768px] pt-3 pb-7 px-5 mx-auto'>
                        These plans are intended for use by your entire company, and require a PBX or Cloud-based phone system that allows you to configure it with SMTP settings we specify. If you’re not sure what’s best for your situation, feel free to <a href="mailto:support@vm2email.com" className='hover:text-blue-400 cursor-pointer text-blue-300 underline' >get in touch</a>.
                    </h5>
                </div>
                <div className="max-w-7xl mx-auto">
                    <div className="text-center py-5 text-white font-semibold text-3xl">Monthly Plans</div>
                    <div className="flex flex-col lg:flex-row items-center justify-center">
                        <div>
                            <div className="font-latoMedium uppercase text-[21px] text-center lg:text-start text-white ml-2">
                                features
                            </div>

                            <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 w-[310px] rounded-l-lg mt-4 lg:mt-2.5">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Number of Mailboxes
                                </div>
                            </div>

                            <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Messages Monthly Aggregate
                                </div>
                            </div>

                            <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Per Message Overage
                                </div>
                            </div>

                            <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Setup Fee
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-5 lg:gap-2.5 mt-2.5">
                            {pricingArray.map((item, index) => {
                                return (<>
                                    <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                                        <div className="flex items-center justify-center -mt-3">
                                            <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoMedium text-[13px] px-4">
                                                {item.planType}
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-center mt-4">
                                            <div className="text-[28px] text-white w-fit font-latoBold relative">
                                                {item.price}<span className="text-[12px] absolute top-0.5 -left-2">$</span>
                                            </div>
                                        </div>

                                        <div className="text-[12px] text-white text-center font-poppinsLight tracking-wide">
                                            Billed Monthly
                                        </div>

                                        <div className="flex items-center justify-center bg-[#153b40] h-[43px] mt-4">
                                            <div className="text-[14px] text-white font-latoBold">{item.mailBoxes}</div>
                                        </div>

                                        <div className="flex items-center justify-center h-[43px]">
                                            <div className="text-[14px] text-white font-latoBold">{item.messageMonthly}</div>
                                        </div>

                                        <div className="flex items-center justify-center bg-[#153b40] h-[43px]">
                                            <div className="text-[14px] text-white text-center font-latoBold">{item.overage} Cents</div>
                                        </div>

                                        <div className="flex items-center justify-center h-[43px]">
                                            <div className="text-[14px] text-white text-center font-latoBold">$0</div>
                                        </div>

                                        <div className="py-5 px-3 lg:px-1.5 xl:px-3">
                                            <Link to='/orderSummary' state={{ ...item, yearly: false }}>
                                                <div className={item.planType === "Plus" ? "flex items-center gap-1 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 rounded-[11px] cursor-pointer" : "flex items-center gap-1 font-latoMedium border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer"}>
                                                    Subscribe Now <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </div>
                <div className="max-w-7xl mx-auto py-5">
                    <div className="text-center py-5 mb-5 text-white font-semibold text-3xl">Yearly Plans</div>
                    <div className="flex flex-col lg:flex-row items-center justify-center">
                        <div>
                            <div className="font-latoMedium uppercase text-[21px] text-center lg:text-start text-white ml-2">
                                features
                            </div>

                            <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 w-[310px] rounded-l-lg mt-4 lg:mt-2.5">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    2 Months FREE Versus Monthly Plan
                                </div>
                            </div>

                            <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Number of Mailboxes
                                </div>
                            </div>

                            <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Messages Monthly Aggregate
                                </div>
                            </div>

                            <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Per Message Overage
                                </div>
                            </div>

                            <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                                <img src={tick} alt="tick" className="w-[15px]" />
                                <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                                    Setup Fee
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-5 lg:gap-1.5 xl:gap-2.5 mt-4">
                            {pricingArray.map((item, index) => {
                                return (<>
                                    <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                                        <div className="flex items-center justify-center -mt-3">
                                            <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoMedium text-[13px] px-4">
                                                {item.planType}
                                            </div>
                                        </div>

                                        <div className="flex items-center justify-center mt-4">
                                            <div className="text-[28px] text-white w-fit font-latoBold relative">
                                                {item.yearlyPrice}
                                                <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                                            </div>
                                        </div>

                                        <div className="text-[12px] text-white text-center font-poppinsLight tracking-wide">
                                            Billed Yearly
                                        </div>

                                        <div className="bg-[#153b40] h-[43px] mt-3 flex items-center justify-center">
                                            <img src={tick} alt="tick" className="w-[15px]" />
                                        </div>

                                        <div className="flex items-center justify-center h-[43px]">
                                            <div className="text-[14px] text-white font-latoBold">{item.mailBoxes}</div>
                                        </div>

                                        <div className="flex items-center justify-center bg-[#153b40] h-[43px]">
                                            <div className="text-[14px] text-white font-latoBold">{item.messageMonthly}</div>
                                        </div>

                                        <div className="flex items-center justify-center h-[43px]">
                                            <div className="text-[14px] text-white text-center font-latoBold">{item.overage} Cents</div>
                                        </div>

                                        <div className="flex items-center justify-center bg-[#153b40] h-[43px]">
                                            <div className="text-[14px] text-white text-center font-latoBold">$0</div>
                                        </div>

                                        <div className="py-5 px-3 lg:px-1.5 xl:px-3">
                                            <Link to='/orderSummary' state={{ ...item, yearly: true }}>
                                                <div className={item.planType === "Plus" ? "flex items-center gap-1 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 rounded-[11px] cursor-pointer" : "flex items-center gap-1 font-latoMedium border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer"}>
                                                    Subscribe Now <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>
                {`
          .backgroundImage2 {
            background-image: url(${bgPlan});
            background-size: cover;
          }
        `}
            </style>
        </>
    )
}
