import React from "react";
import voicemail from "../../Assets/voicemail.webp";
import tick from "../../Assets/tick.png";
import bg from "../../Assets/bg.webp";

export default function VoiceMail() {
  return (
    <>
      <div className="backgroundImage">
        <div className="flex flex-col-reverse md:flex-row max-w-5xl mx-auto pt-9 pb-7 lg:pt-24 md:pb-20 px-7 lg:px-0 gap-10 md:gap-5 lg:gap-10">
          <div className="w-full flex items-center justify-center">
            <img src={voicemail} alt="voicemail" className="w-full sm:w-[80%] md:w-full" />
          </div>

          <div className="w-full mt-3">
            <div className="text-[22px] md:text-[25px] text-center md:text-start text-custom font-latoBold">
              vm2email.com makes business voice <br className="hidden md:flex" /> mail transcription easy
            </div>

            <div className="text-[12.5px] md:text-[15px] text-center md:text-start text-[#646464] font-latoMedium mt-3 md:mt-2.5">
              At vm2email.com, we streamline your communication with seamless voicemail-to-text services. Our
              state-of-the-art system quickly transcribes your audio messages and sends them straight to your
              inbox, providing you with a readable and searchable format.
            </div>

            <div className="text-[15px] md:text-[17px] text-custom text-center md:text-start font-latoBold mt-6">
              Why miss a beat when you can:
            </div>

            <div className="flex gap-2 mt-6 md:mt-4">
              <img src={tick} alt="tick" className="w-[15px] h-[15px] mt-1" />
              <div className="text-[15px] text-[#646464] font-latoMedium">
                <span className="text-custom font-latoBold">Seamlessly Integrate:</span> With easy setup, your
                voicemails are <br className="hidden md:flex" /> transcribed to text and delivered to your
                preferred email, promptly.
              </div>
            </div>

            <div className="flex gap-2 mt-6 md:mt-3">
              <img src={tick} alt="tick" className="w-[15px] h-[15px] mt-1" />
              <div className="text-[15px] text-[#646464] font-latoMedium">
                <span className="text-custom font-latoBold">Multitask Like a Pro:</span> Scan through your
                transcribed voicemails during meetings or on the go, without the need for playback.
              </div>
            </div>

            <div className="flex gap-2 mt-6 md:mt-3">
              <img src={tick} alt="tick" className="w-[15px] h-[15px] mt-1" />
              <div className="text-[15px] text-[#646464] font-latoMedium">
                <span className="text-custom font-latoBold"> Never Lose Track:</span> Your email's search
                functionality allows you to pinpoint important messages by keywords or contact information.
              </div>
            </div>

            <div className="text-[14px] text-center md:text-start text-custom font-latoBold mt-10 md:mt-5">
              Stay connected, stay efficient, and never let an important message slip through the cracks
              again!
            </div>
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .backgroundImage {
            background-image: url(${bg});
            background-size: cover;
            background-position: center;
          }
        `}
      </style>
    </>
  );
}
