import React from 'react'

export default function Terms() {
    return (
        <div className='p-10 max-w-[1500px] mx-auto'>
            <h1 className='text-center text-2xl mb-5'>vm2email.com Voice Mail Transcription Service Terms and Conditions</h1>

            <h2 className='text-center text-xl mb-10'>DESCRIPTION OF SERVICE</h2>
            <p>vm2email.com provides a service for transcribing audio messages into text and delivering the text and audio file via email (the “Service”).</p>
            <p>Unless explicitly stated otherwise, any new features that augment or enhance the current Service shall be subject to this Agreement. You understand and agree that the Service is provided to you on an “AS-IS” basis and that vm2email.com assumes no responsibility for the quality, timeliness, deletion, mis-delivery, or failure to store any user communications or personalization settings. You are responsible for obtaining access to the Service, and that access and subsequent usage may involve third-party fees (such as Internet access to use the Service).</p>
            <p>Throughout this document, when the term “SMTP Plan” is used, it refers to a plan covering your entire phone system in which the SMTP settings in the phone system must be configured with ones we specify in order for the service to work properly.</p>

            <h2 className='font-semibold text-lg my-3'>VOICE MAIL MESSAGE TRANSCRIPTION</h2>
            <p>You understand and agree to the following regarding the Service:</p>
            <ul className='list-disc'>
                <li>All audio is transcribed by machine in an automated process and is not human-transcribed.</li>
                <li>Transcription accuracy is not guaranteed. Factors affecting the transcription accuracy include, but are not limited to, poor or unrecognizable audio quality, non-standard accents, excessive background noise, messages that are not spoken in American English, etc.</li>
                <li>Because transcription accuracy cannot be guaranteed and even small transcription errors may significantly alter the overall meaning of the text and/or intention of the original speaker, you agree that you will not make any important decisions based solely upon the transcription text. Furthermore, for plans offering a voicemail summary in the subject line of the email, we cannot guarantee the summary will accurately describe the contents of the voice mail message.</li>
                <li>For audio files whose length exceeds three (3) minutes (or in the case of an SMTP Plan, 90 seconds), only the first 3 minutes (or 90 seconds respectively) of each voice message will be transcribed.</li>
                <li>The number of transcribed voice mail messages monthly as described in the plan you have chosen is the number of voice mail messages beyond which there will be an additional charge per message transcribed, billed in arrears at the end of each billing cycle at the rate specified in your plan choice.</li>
                <li>For the purposes of message tallying, “monthly” corresponds to the period of time between the day of the calendar month on which your billing cycle begins to the same day in the following calendar month (or if the calendar month does not contain that day of the month, the last day of the calendar month).</li>
                <li>For plans that provide monthly message “rollover”, the maximum number of messages transcribed each month before the accrual of additional per-message charges shall be increased by the monthly number as specified in your plan minus the number of transcribed messages during the prior billing period (or zero, whichever is greater). Message “rollover” is calculated monthly and any “rollover” messages not used during the following billing cycle are forfeited. Message “rollover” is not calculated in your first billing cycle.</li>
                <li>By default, the maximum number of messages that will be transcribed beyond the number of monthly messages included in your plan will be 50% of the number of monthly messages included in your plan; however, this may be lowered upon request or raised upon request at our sole discretion. Additional voice mail messages received beyond this number will still be delivered via email but will not be transcribed.</li>
                <li>Emails sent to our service without either an audio or fax attachment will be handled as follows:
                    <ul className='list-disc'>
                        <li>Non-SMTP Plans: These messages will not be processed but will instead be discarded.</li>
                        <li>SMTP Plans: As a courtesy, these email messages will be relayed to their destination at no additional charge and these relays are not tallied against the number of messages specified by your plan. Such emails may be limited to no more than ten percent (10%) of the total volume of email messages relayed through our servers by your phone system.</li>
                    </ul>
                </li>
                <li>To preserve the integrity of our Service, we reserve the right to deny the processing of any emails that may be determined by our algorithms to be “spam” or of a malicious nature. Our servers may not relay emails containing web links (URLs). We also reserve the right, at our discretion, not to forward email messages that contain attachments with content other than audio or fax.</li>
            </ul>

            <h2 className='font-semibold text-lg my-3'>YOUR REGISTRATION OBLIGATIONS</h2>
            <p>In consideration of your use of the Service, you represent that you are of legal age to form a binding contract and are not a person barred from receiving the Service under the laws of the United States or other applicable jurisdiction. You also agree to: (a) provide true, accurate, current, and complete information about yourself as prompted by the Service’s registration form (such information being the “Registration Data”) and (b) maintain and promptly update the Registration Data to keep it true, accurate, current, and complete. If you provide any information that is untrue, inaccurate, not current, or incomplete, or vm2email.com has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, vm2email.com has the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof). Persons under the age of 18 may not sign up for a vm2email.com account or use any vm2email.com services.</p>

            <h2 className='font-semibold text-lg my-3'>PAYMENT AND FEES</h2>
            <p>Payment by Credit Card. You hereby authorize vm2email.com to charge your credit card as set forth in the Payment Information section of the Registration Form for a monthly Services fee (plus any transaction use, or other taxes, or other surcharges imposed by any governmental authority) and overage fees to vm2email.com at the agreed upon rate.</p>

            <h2 className='font-semibold text-lg my-3'>MEMBER ACCOUNT PASSWORD AND SECURITY</h2>
            <p>You are fully responsible for all activities that occur under your password(s) or account designation(s). You agree to (a) immediately notify vm2email.com of any unauthorized use of your password or account or any other breach of security and (b) ensure that you exit from your account at the end of each session. vm2email.com cannot and will not be liable for any loss or damage arising from your failure to comply with this section.</p>

            <h2 className='font-semibold text-lg my-3'>TRANSMITTED CONTENT</h2>
            <p>You understand that all information, data, text, software, music, sound, photographs, graphics, audio, video messages, or other materials (“Content”), whether publicly posted or privately transmitted, are the sole responsibility of the person from which such Content originated. This means that you, and not vm2email.com, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available via the Service. vm2email.com does not control the Content posted via the Service and, as such, does not guarantee the accuracy, integrity, or quality of such Content. Under no circumstances will vm2email.com be liable in any way for any Content, including but not limited to, for any errors or omissions in any Content, or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted, or otherwise made available via the Service. You agree that Voice Messages left that are not clearly audible, thickly accented, at low volume, or any other characteristic that does not result in accurate transmission and transcription are a consequence of the audio quality of the party leaving the message. vm2email.com cannot guarantee that every call has a high-quality connection or that every caller will speak clearly.</p>

            <h2 className='font-semibold text-lg my-3'>ACCEPTABLE USE POLICY</h2>
            <ul className='list-disc'>
                <li>Each account created with vm2email.com is authorized for use by a single business entity and it is prohibited for multiple business entities to share a single account. A single business entity may use a single account for multiple physical locations; provided, however, that the number of locations/systems per account does not exceed ten (10).</li>
                <li>The Service may not be used in connection with or to facilitate any criminal or other illegal activity.</li>
                <li>If data traffic directed to our servers is determined in our sole discretion to be significantly in excess of average traffic of customers using your same plan type, vm2email.com reserves the right to block or throttle such traffic.</li>
            </ul>

            <h2 className='font-semibold text-lg my-3'>MODIFICATIONS TO SERVICE</h2>
            <p>vm2email.com reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that vm2email.com shall not be liable to you or to any third party for any modification, suspension, or discontinuance of the Service.</p>

            <h2 className='font-semibold text-lg my-3'>TERMINATION</h2>
            <p>You agree that vm2email.com, in its sole discretion, may terminate your password, account (or any part thereof) or use of the Service, and remove and discard any Content within the Service, for any reason, including, without limitation, for lack of use or if vm2email.com believes that you have violated or acted inconsistently with the letter or spirit of the Terms of Service. vm2email.com may also in its sole discretion and at any time discontinue providing the Service, or any part thereof, with or without notice. You agree that any termination of your access to the Service under any provision of this Terms of Service may be effected without prior notice, and acknowledge and agree that vm2email.com may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Service. Further, you agree that vm2email.com shall not be liable to you or any third party for any termination of your access to the Service.</p>

            <h2 className='font-semibold text-lg my-3'>vm2email.com’s PROPRIETARY RIGHTS</h2>
            <p>You acknowledge and agree that the Service and any necessary software used in connection with the Service (“Software”) contain proprietary and confidential information that is protected by applicable intellectual property and other laws. You further acknowledge and agree that Content contained in sponsor advertisements or information presented to you through the Service or by advertisers is protected by copyrights, trademarks, service marks, patents, or other proprietary rights and laws. Except as expressly authorized by vm2email.com or advertisers, you agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Service or the Software, in whole or in part.</p>

            <h2 className='font-semibold text-lg my-3'>INDEMNITY</h2>
            <p>You agree to indemnify and hold vm2email.com, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of Content you submit, post, transmit, or make available through the Service, your use of the Service, your connection to the Service, your violation of the Terms of Service, or your violation of any rights of another.</p>

            <h2 className='font-semibold text-lg my-3'>DISCLAIMER OF WARRANTIES</h2>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
            <ul className='list-disc'>
                <li>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. vm2email.com EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.</li>
                <li>vm2email.com MAKES NO WARRANTY THAT (i) THE SERVICE WILL MEET YOUR REQUIREMENTS, (ii) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.</li>
                <li>ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.</li>
                <li>NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM vm2email.com OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS OF SERVICE.</li>
            </ul>

            <h2 className='font-semibold text-lg my-3'>LIMITATION OF LIABILITY</h2>
            <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT vm2email.com SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF vm2email.com HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (v) ANY OTHER MATTER RELATING TO THE SERVICE.</p>

            <h2 className='font-semibold text-lg my-3'>GENERAL INFORMATION</h2>
            <p>The Terms of Service constitute the entire agreement between you and vm2email.com and govern your use of the Service, superseding any prior agreements between you and vm2email.com (including, but not limited to, any prior versions of the Terms of Service). You also may be subject to additional terms and conditions that may apply when you use affiliate or other vm2email.com services, third-party content, or third-party software. The Terms of Service and the relationship between you and vm2email.com shall be governed by the laws of the State of Michigan without regard to its conflict of law provisions. You and vm2email.com agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Wayne, Michigan. The failure of vm2email.com to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. If any provision of the Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms of Service remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or the Terms of Service must be filed within one (1) year after such claim or cause of action arose or be forever barred.</p>

            <h2 className='font-semibold text-lg my-3'>VIOLATIONS</h2>
            <p>Please report any violations of the Terms of Service to our Customer Support group at support@vm2email.com.</p>


        </div>
    )
}
