import React, { useState } from "react";
import logo from "../Assets/logo.webp";
import bill from "../Assets/bill.png";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseURL } from "../config";

const statesConst = {
  "United State": ["AK", "AL", "AR", "AS", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "GU", "HI", "IA", "ID", "IL", "IN", "KS", "KY", "LA", "MA", "MD", "ME", "MI", "MN", "MO", "MP", "MS", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UM", "UT", "VA", "VI", "VT", "WA", "WI", "WV", "WY"],
  "Canada": ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"]
}

export default function OrderSummary() {
  const location = useLocation();
  const planData = location.state;
  const navigate = useNavigate();
  const [newData, setNewData] = useState({
    discountCode: "",
    cardNumber: "",
    month: "",
    year: "",
    SecCode: "",
    firstName: "",
    lastName: "",
    country: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    accountFirstName: "",
    accountLastName: "",
    email: "",
    company: "",
    password: "",
    confirmPassword: "",
    phone: "",
  })
  console.log('newData', newData)
  const handleChange = (e) => setNewData({ ...newData, [e.target.name]: e.target.value })

  function isValidCreditCardNum(cardNum) {
    let cleanCardNum = cardNum.replace(/\D/g, '');

    let sum = 0;
    let shouldDouble = false;

    for (let i = cleanCardNum.length - 1; i >= 0; i--) {
      let digit = parseInt(cleanCardNum.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  }


  const handleDiscountApply = async (e) => {
    e.preventDefault();
    await axios.post(BaseURL + "/ApplyDiscountCode", { discountCode: newData?.discountCode })
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        toast.error("This code is invalid!");
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidCreditCardNum(newData.cardNumber)) {
      return toast.error("Please enter a valid credit card number");
    }
    if (/^(?=.*\d)(?=.*[!@#$%^&*])?[A-Za-z\d!@#$%^&*]{8,}$/.test(newData.password)) { } else {
      return toast.error("Please enter a valid password");
    }
    let body = {
      CardNumber: newData?.cardNumber,
      ExpirationDate: newData?.month + "/" + newData?.year,
      SecurityCode: newData?.SecCode,
      FirstName: newData?.firstName,
      LastName: newData?.lastName,
      Country: newData?.country,
      Street: newData?.street,
      City: newData?.city,
      State: newData?.state,
      Zip: newData?.zip,
      AccountEmail: newData?.email,
      AccountCompany: newData?.company,
      AccountPassword: newData?.password,
      AccountPhone: newData?.phone,
    };
    await axios.post(BaseURL + "/PlaceOrder", body)
      .then((response) => {
        toast.success(response?.message || "success")
      })
      .catch((error) => {
        toast.error(error?.message);
      });

  }

  return (
    <div className="bg-[#FFFFFF] md:bg-[#F8FDFE]">
      <div className="py-10">
        <div className="flex items-center justify-center">
          <img
            src={logo}
            alt="logo"
            className="w-[135px] h-[64px] cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="px-4 lg:px-20">
          <div className="text-[20px] md:text-[30px] lg:text-[40px] text-[#FFFFFF] text-center font-latoBold bg-gradient-to-r from-[#29C1D2] to-[#5CD047] py-3 rounded-t-lg md:rounded-t-3xl mt-10">
            Order Summary
          </div>

          <form onSubmit={handleSubmit} className="border-l border-r border-b md:border border-[#B0B0B0] rounded-b-xl md:rounded-xl mt-0 md:mt-9 md:px-4 pt-4 md:pt-8 pb-10">
            <div className="flex flex-col md:flex-row gap-8 md:gap-6 lg:gap-12">
              <div className="w-full md:w-1/2 h-fit md:bg-[#B2B2B20D] md:border md:border-[#0B373C40] md:shadow-dropBox3 md:px-2 md:py-6 rounded-xl">
                <div className="flex items-center justify-between px-6 lg:px-11">
                  <div className="text-[15px] lg:text-[17px] font-custom text-[#33333366]">Item</div>
                  <div className="text-[15px] lg:text-[17px] font-custom text-[#33333366]">Quantity</div>
                  <div className="text-[15px] lg:text-[17px] font-custom text-[#33333366]">Price</div>
                </div>

                <hr className="text-[#C1C1C1] my-3 md:my-5" />

                <div className="px-6 lg:px-11">
                  <div className="flex items-center justify-between">
                    <div className="text-custom text-[14px] md:text-[16px] lg:text-[18px] font-custom w-full">
                      {planData?.planType} ({planData?.yearly ? "Yearly" : "Monthly"})
                    </div>

                    <div className="w-full">
                      <input
                        type="text"
                        value="1"
                        className="bg-transparent border border-[#CCCCCC] outline-none w-[95px] text-[14px] lg:text-[16px] font-custom text-[#555555] pt-1.5 pb-1.5 lg:pb-0.5 px-4 text-end"
                      />
                    </div>

                    <div className="text-custom text-[14px] md:text-[16px] lg:text-[18px] font-custom">
                      ${planData?.yearly ? planData?.yearlyPrice : planData?.price}
                    </div>
                  </div>

                  <div className="flex items-center gap-1 mt-5 md:mt-8">
                    <img src={bill} alt="bill" className="w-[14px] h-[14px]" />
                    <div className="text-[13px] md:text-[14px] text-custom font-custom">
                      Billed every 1 month(s)
                    </div>
                  </div>

                  <div className="text-[13px] md:text-[15px] font-custom text-[#999999] mt-1.5 md:mt-2.5">
                    Up to {planData?.messageMonthly} messages transcribed across all <br className="flex md:hidden lg:flex" />{" "}
                    mailboxes; {planData?.overage} cents per additional transcribed <br className="flex md:hidden lg:flex" />{" "}
                    message; up to {planData?.mailBoxes} mailboxes.
                  </div>

                  <form onSubmit={handleDiscountApply} className="flex items-center gap-3 border-t border-b border-dashed border-[#E0E0E0] md:border-[#0B373C] mt-5 md:mt-7 px-6 lg:px-9 py-4 md:py-5">
                    <input
                      type="text"
                      name="discountCode"
                      value={newData?.discountCode}
                      onChange={handleChange}
                      placeholder="Discount Code"
                      className="border border-[#CCCCCC] bg-transparent outline-none text-[#555555] font-custom text-[14px] md:text-[15px] py-2 px-5"
                      required
                    />

                    <button type="submit" className="text-[14px] text-[#428BCA] font-custom cursor-pointer">Apply</button>
                  </form>

                  <div className="flex items-center justify-between px-6 lg:px-9 mt-4 md:mt-6">
                    <div className="text-[16px] md:text-[18px] text-[#28A5ED] font-custom uppercase">
                      Total
                    </div>
                    <div className="text-[16px] md:text-[18px] text-[#28A5ED] font-custom">${planData?.yearly ? planData?.yearlyPrice : planData?.price}</div>
                  </div>

                  <hr className="text-[#E0E0E0] mt-4 block md:hidden" />
                </div>
              </div>

              <div className="w-full md:w-1/2 px-6 md:px-0">
                <div>
                  <div className="text-[16px] md:text-[18px] lg:text-[20px] text-custom font-latoBold">
                    Payment Information
                  </div>

                  <input
                    type="text"
                    placeholder="Card Number*"
                    className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4 mt-4"
                    name="cardNumber"
                    value={newData.cardNumber}
                    onChange={handleChange}
                    required
                  />

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <div className="w-full flex gap-3">
                      <div className="text-nowrap">
                        Exp. Date
                      </div>
                      <select
                        type="text"
                        name="month"
                        value={newData?.month}
                        onChange={handleChange}
                        placeholder="Select Month"
                        className="bg-transparent border border-[#CCCCCC] w-14 outline-none text-[14px] font-custom text-[#555555] py-2 px-2"
                        required
                      >
                        <option value="01">01 - January</option>
                        <option value="02">02 - February</option>
                        <option value="03">03 - March</option>
                        <option value="04">04 - April</option>
                        <option value="05">05 - May</option>
                        <option value="06">06 - June</option>
                        <option value="07">07 - July</option>
                        <option value="08">08 - August</option>
                        <option value="09">09 - September</option>
                        <option value="10">10 - October</option>
                        <option value="11">11 - November</option>
                        <option value="12">12 - December</option>
                      </select>
                      <select
                        type="text"
                        name="year"
                        value={newData?.year}
                        onChange={handleChange}
                        placeholder="Select Month"
                        className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-2"
                        required
                      >
                        <option hidden disabled value="">Select year</option>
                        <option value="2024">2024</option>
                        <option value="2028">2028</option>
                        <option value="2032">2032</option>
                        <option value="2036">2036</option>
                        <option value="2040">2040</option>
                      </select>
                      {/* <input
                        type="text"
                        placeholder="Exp. Date*"
                        className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      /> */}
                    </div>

                    <input
                      type="text"
                      placeholder="Sec. code"
                      maxLength={4}
                      name="SecCode"
                      value={newData?.SecCode}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="text"
                      placeholder="First Name*"
                      name="firstName"
                      value={newData?.firstName}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />

                    <input
                      type="text"
                      placeholder="Last Name*"
                      name="lastName"
                      value={newData?.lastName}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>

                  <select
                    id="countries"
                    className="bg-[#FFFFFF] border border-[#CCCCCC] text-[#555555] text-[15px] outline-none font-custom text-sm rounded-lg w-full p-2.5 mt-3.5 cursor-pointer"
                    name="country"
                    value={newData.country}
                    onChange={handleChange}
                  >
                    <option value="" disabled hidden >Choose a country</option>
                    <option value="United State">United State</option>
                    <option value="Canada">Canada</option>
                  </select>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="text"
                      placeholder="Street*"
                      name="street"
                      value={newData?.street}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />

                    <input
                      type="text"
                      placeholder="City*"
                      name="city"
                      value={newData?.city}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <select
                      id="state"
                      name="state"
                      value={newData?.state}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                    >
                      <option value="" disabled hidden >Select State</option>
                      {Object.entries(statesConst).map(state => {
                        if (state[0] === newData?.country) {
                          return state[1].map(item => <option value={item} >{item}</option>)
                        }
                      })}
                    </select>

                    <input
                      type="text"
                      name="zip"
                      value={newData?.zip}
                      onChange={handleChange}
                      placeholder="Zip*"
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>
                </div>

                <div className="mt-6">
                  <div className="text-[16px] md:text-[18px] lg:text-[20px] text-custom font-latoBold">
                    Account information <span className="text-[#FF0000]">*</span>
                  </div>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="text"
                      placeholder="First Name*"
                      name="accountFirstName"
                      value={newData?.accountFirstName}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />

                    <input
                      type="text"
                      placeholder="Last Name*"
                      name="accountLastName"
                      value={newData?.accountLastName}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      value={newData?.email}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />

                    <input
                      type="text"
                      placeholder="Company*"
                      name="company"
                      value={newData?.company}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="text"
                      placeholder="Password*"
                      name="password"
                      value={newData.password}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />

                    <input
                      type="text"
                      placeholder="Reenter Password*"
                      name="confirmPassword"
                      value={newData?.confirmPassword}
                      onChange={handleChange}
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                      required
                    />
                  </div>

                  <input
                    type="text"
                    placeholder="Phone*"
                    name="phone"
                    value={newData?.phone}
                    onChange={handleChange}
                    className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4 mt-4"
                    required
                  />
                </div>

                {/* <div className="mt-6">
                  <div className="text-[16px] md:text-[18px] lg:text-[20px] text-custom font-latoBold">
                    Billing Address
                  </div>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="text"
                      placeholder="Street*"
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                    />

                    <input
                      type="text"
                      placeholder="City*"
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                    />
                  </div>

                  <div className="flex items-center gap-5 lg:gap-10 mt-3.5">
                    <input
                      type="text"
                      placeholder="State*"
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                    />

                    <input
                      type="text"
                      placeholder="Zip*"
                      className="bg-transparent border border-[#CCCCCC] w-full outline-none text-[14px] font-custom text-[#555555] py-2 px-4"
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <div className="text-[16px] text-custom font-custom text-center mt-10 px-6 md:px-0">
              By clicking <span className="font-latoBold">'Subscribe'</span> you agree to the{" "}
              <span className="text-[#428BCA]">Terms of Service</span>.
            </div>

            <div className="flex items-center justify-center mt-6">
              <button type="submit" className="text-[#FFFFFF] text-[16px] font-custom w-fit bg-gradient-to-r from-[#2DC2C8] to-[#4FCC6A] py-3 px-12 rounded-xl cursor-pointer">
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
