import React, { useEffect, useRef, useState } from "react";
import logo from "../Assets/logo.webp";
import { BiSolidUser } from "react-icons/bi";
import { HiMenu } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const menuRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [isClick, setIsClick] = useState(false);

  const handleClick = () => {
    setIsClick(!isClick);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsClick(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`${
        location?.pathname === "/emailMapping" ||
        location?.pathname === "/billing" ||
        location?.pathname === "/pricing" ||
        location?.pathname === "/profile"
          ? "shadow-dropHeader1"
          : "shadow-dropHeader md:shadow-none"
      } flex items-center justify-between py-3 md:py-4 px-7 lg:px-10 xl:px-14 2xl:px-32`}
    >
      <img
        src={logo}
        alt="logo"
        className="w-[75px] h-[35px] md:w-[120px] md:h-[60px] cursor-pointer"
        onClick={() => navigate("/")}
      />

      <div className="hidden md:flex items-center gap-10 text-[18px]">
        <li
          className={`${
            location?.pathname === "/faqs"
              ? "font-latoSemiBold text-[#26C0D2]"
              : "font-latoMedium text-custom"
          } cursor-pointer list-none`}
        >
          <div onClick={() => navigate("/faqs")}>FAQ</div>
        </li>

        <li className="cursor-pointer font-latoMedium text-custom list-none">
          <div onClick={() => navigate("/")}>
            <a href="#pricing"> Pricing</a>
          </div>
        </li>

        <li
          className={`${
            location?.pathname === "/contact"
              ? "font-latoSemiBold text-[#26C0D2]"
              : "font-latoMedium text-custom"
          } cursor-pointer list-none`}
        >
          <div onClick={() => navigate("/contact")}>Contact</div>
        </li>
      </div>

      <div
        className="hidden md:flex items-center gap-2 bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-2.5 px-3.5 rounded-lg cursor-pointer"
        onClick={() => navigate("/login")}
      >
        <BiSolidUser />
        <div className="-mt-0.5 text-[14px] font-latoMedium">Log in</div>
      </div>

      <HiMenu
        className="flex md:hidden text-[30px] cursor-pointer"
        onClick={(e) => {
          handleClick();
          e.stopPropagation();
        }}
      />

      {isClick && (
        <div ref={menuRef} className="absolute top-14 right-2 bg-[#FFFFFF] shadow-dropBox z-[99]">
          <div className="pt-7 pb-5 px-14">
            <img
              src={logo}
              alt="logo"
              className="w-[110px] h-[55px] cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>

          <div className="flex flex-col items-center gap-4 text-[18px]">
            <li
              className={`${
                location?.pathname === "/faqs"
                  ? "font-latoSemiBold text-[#26C0D2]"
                  : "font-latoMedium text-custom"
              } cursor-pointer list-none shadow-dropBox1 w-full py-3 text-center`}
            >
              <div onClick={() => navigate("/faqs")}>FAQ</div>
            </li>

            <li className="cursor-pointer font-latoMedium text-custom list-none shadow-dropBox1 w-full py-3 text-center">
              <div>Pricing</div>
            </li>

            <li
              className={`${
                location?.pathname === "/contact"
                  ? "font-latoSemiBold text-[#26C0D2]"
                  : "font-latoMedium text-custom"
              } cursor-pointer list-none shadow-dropBox1 w-full py-3 text-center`}
            >
              <div onClick={() => navigate("/contact")}>Contact</div>
            </li>
          </div>

          <div className="flex items-center justify-center w-full py-7">
            <div
              className="flex items-center gap-2 w-fit bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-7 rounded-lg cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <BiSolidUser />
              <div className="-mt-0.5 text-[14px] font-latoMedium">Log in</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
