import React from "react";
import footer from "../../Assets/footer.webp";
import logo from "../../Assets/logo1.webp";
import line from "../../Assets/line.png";
import facebook from "../../Assets/facebook.png";
import instagram from "../../Assets/instagram.png";
import twitter from "../../Assets/twitter.png";
import linkedIn from "../../Assets/linkedIn.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="footerbackgroundImage">
        <div className="mx-auto w-full max-w-screen-xl py-6 lg:pt-32 lg:pb-16 px-7 xl:px-0">
          <div className="md:flex md:justify-between">
            <div className="mb-6 md:mb-0">
              <img src={logo} alt="logo" className="w-[75px] md:w-[100px] lg:w-[180px]" />

              <div className="hidden md:block">
                <div className="text-[15px] lg:text-[22px] flex font-latoBold text-white mt-14">
                  <span className="relative w-fit">
                    F <img src={line} alt="line" className="absolute -bottom-1" />
                  </span>
                  ollow us
                </div>

                <div className="flex items-center gap-3.5 md:gap-2 lg:gap-3.5 mt-7">
                  <img src={facebook} alt="facebook" className="w-[45px] h-[45px]" />
                  <img src={twitter} alt="twitter" className="w-[45px] h-[45px]" />
                  <img src={linkedIn} alt="linkedIn" className="w-[45px] h-[45px]" />
                  <img src={instagram} alt="instagram" className="w-[45px] h-[45px]" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-1.5 lg:gap-14 xl:gap-28 sm:grid-cols-3 mt-12 md:mt-0">
              <div>
                <div className="text-[15px] lg:text-[22px] flex font-latoBold text-white">
                  <span className="relative w-fit">
                    Q <img src={line} alt="line" className="absolute -bottom-1" />
                  </span>
                  uick links
                </div>

                <ul className="text-white font-custom mt-6 md:mt-9">
                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href="#home">Home</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href="#faq">FAQ</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <Link to="/terms">Terms</Link>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href="#contact">Contact</a>
                  </li>

                  <li className="text-[14px] lg:text-[17px] opacity-90">
                    <a href="#pricing">Pricing</a>
                  </li>
                </ul>
              </div>

              <div>
                <div className="text-[15px] lg:text-[22px] flex font-latoBold text-white">
                  <span className="relative w-fit">
                    M <img src={line} alt="line" className="absolute -bottom-1" />
                  </span>
                  ore
                </div>

                <ul className="text-white font-custom mt-6 md:mt-9">
                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Privacy Policy</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Terms & Conditions</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Sitemap</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Disclaimer</a>
                  </li>

                  <li className="text-[14px] lg:text-[17px] opacity-90">
                    <a href>Support</a>
                  </li>
                </ul>
              </div>

              <div>
                <div className="text-[15px] lg:text-[22px] flex font-latoBold text-white">
                  <span className="relative w-fit">
                    P <img src={line} alt="line" className="absolute -bottom-1" />
                  </span>
                  lans
                </div>

                <ul className="text-white font-custom mt-6 md:mt-9">
                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Getting Started</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Personal</a>
                  </li>

                  <li className="mb-3 text-[14px] lg:text-[17px] opacity-90">
                    <a href>Business</a>
                  </li>

                  <li className="text-[14px] lg:text-[17px] opacity-90">
                    <a href>Enterprise</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="block md:hidden">
              <div className="text-[18px] flex font-poppinsSemiBold text-white mt-14">
                <span className="relative w-fit">
                  F <img src={line} alt="line" className="absolute -bottom-1" />
                </span>
                ollow us
              </div>

              <div className="flex items-center gap-3.5 mt-7">
                <img src={facebook} alt="facebook" className="w-[45px] h-[45px]" />
                <img src={twitter} alt="twitter" className="w-[45px] h-[45px]" />
                <img src={linkedIn} alt="linkedIn" className="w-[45px] h-[45px]" />
                <img src={instagram} alt="instagram" className="w-[45px] h-[45px]" />
              </div>
            </div>
          </div>

          <hr className="my-6 border-[#FFFFFF] opacity-20 sm:mx-auto lg:mt-20 lg:mb-16" />

          <div className="flex items-center justify-center">
            <span className="text-[12px] md:text-[15px] text-white text-center font-custom">
              Copyright 2024{" "}
              <a href="https://flowbite.com/" className="text-[#29c1d3] font-bold">
                vm2email.com
              </a>{" "}
              | All Rights Reserved
            </span>
          </div>
        </div>
      </footer>

      <style jsx>
        {`
          .footerbackgroundImage {
            background-image: url(${footer});
            background-size: cover;
          }
        `}
      </style>
    </>
  );
}
