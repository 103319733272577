import React, { useEffect, useRef, useState } from "react";
import star from "../../Assets/star.png";
import ring from "../../Assets/ring.webp";
import bg from "../../Assets/email-bg.webp";
import email from "../../Assets/email-img.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import inbox from "../../Assets/inbox.png";
import email1 from "../../Assets/email.png";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import toast from "react-hot-toast";
import { BaseURL } from "../../config";

export default function Email() {
  const recaptchaRef = useRef(null);
  const [emailState, setEmailState] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const urlContainsContact = window.location.hash === "#contact";
    const contactElement = document.getElementById("contact");

    if (urlContainsContact && contactElement) {
      contactElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    // const token = recaptchaRef.current.getValue();
    // recaptchaRef.current.reset();
    await axios.post(BaseURL + '/RequestTrial', { email: emailState })
      .then((response) => {
        toast.success('Email successfully registered')
        setLoading(false)
      })
      .catch((error) => {
        toast.error('Email registration failed!')
        setLoading(false)
      });

  };

  return (
    <div id="contact">
      <div className="backgroundImage1 lg:h-[100vh] mb-2 md:mb-14">
        <div className="w-full flex items-center justify-center pt-10 md:pt-14 lg:pt-40">
          <div className="w-fit">
            <div className="text-[22px] sm:text-[30px] md:text-[36px] 2xl:text-[44px] text-custom text-center md:leading-[50px] font-latoBold">
              Experience Simplicity With <br />
              <span className="w-fit relative">
                Our 1-Minute{" "}
                <span className="relative w-[200%]">
                  Signup - <img src={ring} alt="ring" className="absolute top-0 -left-1 w-full md:h-[55px]" />
                </span>{" "}
                <img src={star} alt="star" className="hidden md:flex absolute top-3.5 -left-8 w-[26px]" />
              </span>
              <br />{" "}
              <span className="text-[16px] md:text-[26px] text-[#093136] font-latoBold">
                No Credit Card Required
              </span>
              <div className="text-xs text-center max-w-[400px] mx-auto w-full text-[#093136] font-latoMedium">
                Check out the quality and speed for yourself with no obligation or commitment. All we need is your email address!
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto flex flex-col-reverse items-center justify-center gap-5 lg:gap-14 md:flex-row mt-0 md:mt-10 px-6 xl:px-0">
          <div className="flex items-center justify-center">
            <img src={email} alt="email" className="w-[390px] md:w-[400px] lg:w-[450px] xl:w-[500px]" />
          </div>

          <form onSubmit={handleSubmit} className="bg-[#d2f3f4] px-4 sm:px-8 pt-6 pb-8 sm:pt-8 md:py-12 sm:pb-10 rounded-2xl mt-6 md:mt-0 relative">
            <div className="font-latoBold text-[15px] text-custom">Email Address</div>

            <div className="mt-2 w-full relative">
              <input
                type="email"
                value={emailState}
                onChange={({ target }) => setEmailState(target.value)}
                placeholder="Enter Email Address"
                className="border border-[#7bd9e2] w-full text-[14px] text-custom font-custom outline-none py-3 px-4 rounded-lg"
                required
              />

              <img src={inbox} alt="inbox" className="w-[20px] absolute top-1/3 right-5" />
            </div>

            <div className="mt-4 w-full flex justify-center gap-3.5">
              {/* <input
                type="text"
                value={"CAAPTCHA"}
                disabled
                className="border border-[#7bd9e2] text-custom w-full bg-[#FFFFFF] outline-none text-center font-bold py-3 px-5 rounded-lg"
              /> */}

              <ReCAPTCHA ref={recaptchaRef} sitekey="6Lcne8QpAAAAAM0P6wv54YhgSOIm4ncjwDDfwS_T" />

              {/* <input
                type="text"
                placeholder="Type the Captcha"
                className="border border-[#7bd9e2] w-full text-[14px] text-custom font-custom outline-none py-3 px-4 rounded-lg"
              /> */}
            </div>

            <button
              className="flex w-full items-center justify-center font-latoMedium text-[15px] gap-2 bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-4 px-5 rounded-xl mt-4 cursor-pointer"
            >
              Start Free 14-day Trial
              <MdOutlineArrowForwardIos className="text-[15px]" />
            </button>

            <img src={email1} alt="email1" className="w-[26px] absolute -top-7 -right-5" />
          </form>
        </div>
      </div>

      <style jsx>
        {`
          .backgroundImage1 {
            background-image: url(${bg});
            background-size: cover;
          }

          iframe .rc-anchor-light {
            background-color: #ffffff !important;
          }
        `}
      </style>
    </div>
  );
}
