import React from "react";
import Header from "../Header";
import profile from "../../Assets/profile.png";
import mapping from "../../Assets/mapping.png";
import mapping1 from "../../Assets/mapping1.png";
import billing from "../../Assets/billing.png";
import billing1 from "../../Assets/billing1.png";
import subscription from "../../Assets/subscription.png";
import subscription1 from "../../Assets/subscription1.png";
import profile1 from "../../Assets/profile1.png";
import profile2 from "../../Assets/profile2.png";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../LandingPage/Footer";

export default function Sidebar({ children }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <Header />

      <div className="flex flex-col md:flex-row gap-0 md:gap-4 lg:gap-20 px-7 lg:px-10 xl:px-14 2xl:px-32 py-7 lg:py-20">
        <div className="w-full md:w-auto">
          <div className="border border-[#CDE5E8] bg-[#FFFFFF] shadow-dropCard2 px-5 py-4 w-full rounded-lg">
            <div className="text-[18px] text-[#000000] font-custom">Full Name</div>

            <div className="flex flex-col items-center justify-center py-7 px-14">
              <img src={profile} alt="profile" className="w-[100px] h-[100px]" />
              <div className="text-[15px] text-custom opacity-80 font-custom mt-2">admin@brainherd.com</div>
            </div>
          </div>

          <div className="flex flex-row md:flex-col overflow-auto scrollHide border border-[#CDE5E8] bg-[#FFFFFF] shadow-dropCard2 p-3.5 md:p-5 w-full mt-8 md:mt-11 rounded-lg">
            <div
              className={`${
                pathname === "/emailMapping"
                  ? "bg-[#DEF0F2] border-[#67D329]"
                  : "bg-[#FFFFFF] border-transparent"
              } flex items-center gap-4 border-l py-3 px-4 cursor-pointer`}
              onClick={() => navigate("/emailMapping")}
            >
              <img
                src={pathname === "/emailMapping" ? mapping1 : mapping}
                alt="mapping"
                className="w-[14px] h-[16px]"
              />
              <div
                className={`${
                  pathname === "/emailMapping" ? "text-[#26C0D2]" : "text-custom"
                } text-[14px] font-latoMedium w-[200px]`}
              >
                Email Address mapping
              </div>
            </div>

            <hr className="text-[#CCCCCC]" />

            <div
              className={`${
                pathname === "/billing" ? "bg-[#DEF0F2] border-[#67D329]" : "bg-[#FFFFFF] border-transparent"
              } flex items-center gap-4 border-l py-3 px-4 cursor-pointer mt-0 md:mt-6`}
              onClick={() => navigate("/billing")}
            >
              <img
                src={pathname === "/billing" ? billing1 : billing}
                alt="billing"
                className="w-[15px] h-[16px]"
              />
              <div
                className={`${
                  pathname === "/billing" ? "text-[#26C0D2]" : "text-custom"
                } text-[14px] font-latoMedium w-[200px]`}
              >
                Billing
              </div>
            </div>

            <hr className="text-[#CCCCCC]" />

            <div
              className={`${
                pathname === "/pricing" ? "bg-[#DEF0F2] border-[#67D329]" : "bg-[#FFFFFF] border-transparent"
              } flex items-center gap-4 border-l py-3 px-4 cursor-pointer mt-0 md:mt-6`}
              onClick={() => navigate("/pricing")}
            >
              <img
                src={pathname === "/pricing" ? subscription1 : subscription}
                alt="subscription"
                className="w-[16px] h-[16px]"
              />
              <div
                className={`${
                  pathname === "/pricing" ? "text-[#26C0D2]" : "text-custom"
                } text-[14px] font-latoMedium w-[200px]`}
              >
                Subscription
              </div>
            </div>

            <hr className="text-[#CCCCCC]" />

            <div
              className={`${
                pathname === "/profile" ? "bg-[#DEF0F2] border-[#67D329]" : "bg-[#FFFFFF] border-transparent"
              } flex items-center gap-4 border-l py-3 px-4 cursor-pointer mt-0 md:mt-6`}
              onClick={() => navigate("/profile")}
            >
              <img
                src={pathname === "/profile" ? profile2 : profile1}
                alt="profile1"
                className="w-[16px] h-[16px]"
              />
              <div
                className={`${
                  pathname === "/profile" ? "text-[#26C0D2]" : "text-custom"
                } text-[14px] font-latoMedium w-[200px]`}
              >
                Profile
              </div>
            </div>

            <hr className="text-[#CCCCCC]" />
          </div>
        </div>

        <div className="w-full">{children}</div>
      </div>

      <Footer />
    </div>
  );
}
