import React from "react";
import underline from "../../Assets/underline.png";
import star from "../../Assets/star.png";
import robot from "../../Assets/robot.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Header from "../Header";

export default function Home() {
  return (
    <div id="home">
      <Header />

      <div className="grid grid-rows-1 pl-0 md:pl-7 lg:pl-10 xl:pl-14 2xl:pl-32">
        <div className="grid grid-cols-12">
          <div className="col-span-12 md:col-span-5 flex flex-col justify-center">
            <div className="text-[24px] md:text-[26px] lg:text-[36px] xl:text-[40px] 2xl:text-[56px] text-center md:text-start text-custom font-latoBold 2xl:leading-[65px] mt-8 md:mt-0">
              <span className="relative">
                Transcribe
                <img src={underline} alt="underline" className="absolute -bottom-1 right-0 md:w-1/2" />
              </span>{" "}
              <span className="relative">
                Your{" "}
                <img src={star} alt="star" className="absolute -top-1 -right-8 w-[26px] hidden xl:flex" />
              </span>
              <br className="hidden lg:flex" /> Voicemails <br className="flex md:hidden" /> Quickly,{" "}
              <br className="hidden lg:flex xl:hidden 2xl:flex" /> Accurately, and <br /> Effortlessly
            </div>

            <div className="text-[14px] 2xl:text-[16px] text-center md:text-start mt-4 md:mt-3 leading-6 font-latoMedium text-[#646464]">
              In today’s fast-paced world, managing every minute is <br className="flex md:hidden" /> crucial.
              With <br className="hidden 2xl:flex" /> vm2email, turn your voicemail into text{" "}
              <br className="flex md:hidden" /> effortlessly, saving time and{" "}
              <br className="hidden 2xl:flex" /> enhancing productivity. <br className="flex md:hidden" /> Try
              our voicemail transcription service <br className="hidden 2xl:flex" /> today—
              <br className="flex md:hidden" />
              absolutely free!
            </div>

            <div className="w-full flex items-center md:items-start justify-center md:justify-start mt-7 2xl:mt-10">
              <a href="#contact">
                <div className="flex items-center gap-2 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-6 rounded-xl w-fit cursor-pointer">
                  Get Started
                  <MdOutlineArrowForwardIos className="text-[15px]" />
                </div>
              </a>
            </div>
          </div>

          <div className="col-span-12 md:col-span-7 flex items-center justify-end 2xl:h-[calc(100vh-92px)]">
            <img
              src={robot}
              alt="robot"
              className="h-auto w-[90%] md:w-full xl:w-[90%] 2xl:w-[90%] 2xl:h-[100%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
