import React from "react";
import star from "../../Assets/star.png";
import star1 from "../../Assets/green-star.webp";
import star2 from "../../Assets/blue-star.webp";
import Home from "./Home";
import Features from "./Features";
import VoiceMail from "./VoiceMail";
import Service from "./Service";
import Pricing from "./Pricing";
import Questions from "./FAQs";
import ring from "../../Assets/ring.webp";
import bg1 from "../../Assets/bg2.webp";
import chat from "../../Assets/chat.webp";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Footer from "./Footer";
import Email from "./Email";
import About1 from "./About";
import inbox1 from "../../Assets/inbox1.webp";

export default function LandingPage() {
  return (
    <div>
      <Home />

      <div className="bg-[#0b373c] pt-3.5 pb-2">
        <marquee>
          <div className="flex items-center gap-10">
            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star2} alt="star2" className="w-[15px] h-[15px]" />
              Flexibility
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star1} alt="star1" className="w-[15px] h-[15px]" />
              Complimentary Trial
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star2} alt="star2" className="w-[15px] h-[15px]" />
              Discretion
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star1} alt="star1" className="w-[15px] h-[15px]" />
              AI-Enhanced
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star2} alt="star2" className="w-[15px] h-[15px]" />
              No-Hassle Setup
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star1} alt="star1" className="w-[15px] h-[15px]" />
              Complimentary Trial
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star2} alt="star2" className="w-[15px] h-[15px]" />
              Discretion
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star1} alt="star1" className="w-[15px] h-[15px]" />
              AI-Enhanced
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star2} alt="star2" className="w-[15px] h-[15px]" />
              No-Hassle Setup
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star1} alt="star1" className="w-[15px] h-[15px]" />
              Complimentary Trial
            </div>

            <div className="text-[#FFFFFF] font-latoSemiBold flex items-center gap-2 tracking-wide">
              <img src={star2} alt="star2" className="w-[15px] h-[15px]" />
              Flexibility
            </div>
          </div>
        </marquee>
      </div>

      <Features />

      <VoiceMail />

      <Service />

      <Email />

      <Pricing />

      {/* <About1 /> */}

      <Questions />

      <div className="footer-bg w-full h-full">
        <div className="relative px-2 pb-7 md:pb-52">
          <div className="flex items-center justify-center pt-14">
            <div className="w-fit relative">
              <div className="md:leading-[55px]">
                <div className="text-[22px] sm:text-[30px] md:text-[32px] 2xl:text-[44px] text-center text-custom font-latoBold">
                  With{" "}
                  <span className="w-fit relative">
                    vm2email,{" "}
                    <img
                      src={ring}
                      alt="ring"
                      className="hidden md:block absolute top-0 -left-1 w-[100%] h-[55px]"
                    />
                  </span>
                  staying on top of
                </div>

                <div className="text-[22px] sm:text-[30px] md:text-[32px] 2xl:text-[44px] text-center text-custom font-latoBold md:tracking-wide">
                  your communications{" "}
                  <img src={chat} alt="chat" className="w-[20px] h-[20px] md:w-[40px] md:h-[34px] inline" />{" "}
                  has never
                </div>

                <div className="text-[22px] sm:text-[30px] md:text-[32px] 2xl:text-[44px] text-center text-custom font-latoBold">
                  been easier or more efficient.
                </div>
              </div>

              <img
                src={star}
                alt="star"
                className="absolute bottom-1.5 md:-bottom-0.5 right-1.5 md:right-6 w-[22px] md:w-[26px]"
              />
            </div>
          </div>

          <div className="text-[14.5px] text-center text-[#153B40] font-latoBold mt-3">
            Begin with our free trial and transform how you manage your voicemails today.
          </div>

          <div className="w-full mt-3 flex items-center justify-center">
            <a href="#contact">
              <div className="flex items-center gap-2 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-6 rounded-xl w-fit mt-3 md:mt-10 cursor-pointer">
                Get Started
                <MdOutlineArrowForwardIos className="text-[15px]" />
              </div>
            </a>
          </div>

          <div className="flex md:hidden relative items-center justify-center mt-10">
            <img src={inbox1} alt="inbox1" className="w-[275px]" />
          </div>

          <img src={inbox1} alt="inbox1" className="hidden md:block w-[180px] absolute left-16 bottom-7" />
        </div>
      </div>

      <Footer />

      <style>
        {`
          .footer-bg {
            background-image: url(${bg1});
            background-size: cover;
            background-position: center;
          }
        `}
      </style>
    </div>
  );
}
