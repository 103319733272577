import React from "react";
import underline from "../../Assets/underline.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import phone from "../../Assets/phone.webp";
import send from "../../Assets/send.webp";
import send1 from "../../Assets/send1.webp";
import receive from "../../Assets/receive.webp";
import receive1 from "../../Assets/receive1.webp";

export default function Service() {
  return (
    <div className="pt-9 md:pt-12 lg:pt-24 pb-10 md:pb-16 xl:pb-24 px-7 lg:px-0">
      <div className="bg-image">
        <div>
          <div className="text-[22px] sm:text-[30px] md:text-[36px] 2xl:text-[44px] text-custom font-latoBold text-center">
            How vm2email.com{" "}
            <span className="relative w-fit">
              Works <img src={underline} alt="underline" className="absolute -bottom-1 right-0" />
            </span>
          </div>

          <div className="text-[12.5px] md:text-[15px] text-[#6C797B] font-latoMedium text-center mt-6 md:mt-3">
            By working with your existing phone system’s “voice mail to email” functionality. If your phone
            system <br className="hidden lg:flex" /> offers this feature, whereby received voice mail messages
            are emailed automatically to you, along with the <br className="hidden lg:flex" /> audio of the
            message attached to the email, you can benefit from our transcription service!
          </div>

          <div className="max-w-5xl mx-auto">
            <div className="relative w-full mt-11 md:mt-20">
              <div className="w-fit relative">
                <div className="flex flex-col md:flex-row items-center gap-5 md:gap-7 bg-[#FFFFFF] border border-[#ace4ea] shadow-dropCard1 md:shadow-dropCard px-10 py-9 md:pt-1.5 md:pb-5 md:pl-4 md:pr-14 rounded-2xl">
                  <div className="flex items-center justify-center">
                    <img src={phone} alt="phone" className="w-full md:w-[90px] md:-mt-10" />
                  </div>

                  <div className="text-[24px] md:text-[20px] text-center md:text-start text-custom font-latoBold leading-7 mt-2">
                    Setup Your Phone <br /> Service
                  </div>

                  <div className="text-[16px] md:text-[13px] text-center md:text-start text-[#6C797B] font-latoBold">
                    Ensure your phone service can forward a <br className="hidden md:flex" /> copy of your
                    voicemail via email.
                  </div>
                </div>
                <img
                  src={send1}
                  alt="send1"
                  className="hidden lg:flex absolute top-1/2 -right-[18%] w-[80px]"
                />
              </div>
            </div>

            <div className="w-full mt-8 md:mt-14 flex items-center justify-end">
              <div className="w-fit relative">
                <div className="flex flex-col md:flex-row items-center gap-5 md:gap-7 bg-[#FFFFFF] border border-[#ace4ea] shadow-dropCard1 md:shadow-dropCard px-10 py-9 md:pt-1.5 md:pb-5 w-fit md:pl-4 md:pr-20 rounded-2xl">
                  <img src={send} alt="send" className="w-[114px] md:w-[90px] md:-mt-6" />

                  <div className="text-[24px] md:text-[20px] text-center md:text-start text-custom font-latoBold leading-7 mt-2">
                    Send It <br className="hidden md:flex" /> to Us
                  </div>

                  <div className="text-[16px] md:text-[13px] text-center md:text-start text-[#6C797B] font-latoBold">
                    Forward these emails to a unique address we provide. <br className="hidden md:flex" />{" "}
                    We'll send transcriptions to your regular email.
                  </div>
                </div>
                <img
                  src={receive1}
                  alt="receive1"
                  className="hidden lg:flex absolute top-10 -left-[19%] w-[80px]"
                />
              </div>
            </div>

            <div className="w-full mt-8 md:mt-14">
              <div className="flex flex-col md:flex-row items-center gap-5 md:gap-7 bg-[#FFFFFF] border border-[#ace4ea] shadow-dropCard1 md:shadow-dropCard px-10 py-9 md:pt-4 md:pb-5 w-fit md:pl-4 md:pr-16 rounded-2xl">
                <img src={receive} alt="receive" className="w-[114px] md:w-[80px] md:-mt-12" />

                <div className="text-[24px] md:text-[20px] text-center md:text-start text-custom font-latoBold leading-7">
                  Receive and <br className="hidden md:flex" /> Review
                </div>

                <div className="text-[16px] md:text-[13px] text-center md:text-start text-[#6C797B] font-latoBold mt-1">
                  Voila! You’ll now get emails with the voicemail <br className="hidden md:flex" /> text and
                  the original audio file attached, direct to <br className="hidden md:flex" /> your inbox.
                </div>
              </div>
            </div>

            <div className="w-full md:mt-2 flex items-center justify-center">
              <a href="#contact">
                <div className="flex items-center gap-2 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-6 rounded-xl w-fit mt-10 cursor-pointer">
                  Get Started
                  <MdOutlineArrowForwardIos className="text-[15px]" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
