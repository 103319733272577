import React, { useState } from "react";
import Header from "./Header";
import dotline from "../Assets/dotline1.png";
import Footer from "./LandingPage/Footer";
import minus from "../Assets/minus.webp";
import plus from "../Assets/plus.webp";

const faqs = [
  {
    question: "What is voice mail transcription?",
    answer: "Voice mail transcription is the conversion of voice mail audio files into text.",
  },
  {
    question: "How long does transcription take?",
    answer:
      "Transcription is fast. In most circumstances, an email containing the voice mail audio is routed through our servers, transcribed, and forwarded to your email in less than a minute.",
  },
  {
    question: "Is transcription performed by a human?",
    answer: "No. Transcription is performed quickly, automatically, and accurately by machine.",
  },
  {
    question: "How accurate is voice mail transcription?",
    answer:
      "The accuracy of transcribed voice mail messages is dependent upon several factors, including the quality of the audio, how well the speaker enunciates, whether there are specialized words or phrases included that are uncommon in everyday speech, and whether a non-standard accent is present. Typical accuracy with our service is up to 95 percent or even greater, the highest in the industry. But feel free to take advantage of our free trial and see the quality for yourself!",
  },
  {
    question: "Are there any limitations on the free trial?",
    answer:
      "In the free trial you are limited to sending transcriptions to a single email address so that you can evaluate the service. The trial will last 2 weeks. After that, any messages sent to your special vm2email.com will still be forwarded for an additional 2 weeks, but the email will contain a reminder of the trial status and encourage you to sign up for service. After 4 weeks, any messages sent to the special vm2email.com email address will be discarded.",
  },
  {
    question: "is the transcription service HIPAA compliant?",
    answer:
      "Because vm2email.com relies on third-party vendors for some functions, and email itself is not considered a secure means of communication, vm2email.com does not claim to be HIPAA-compliant.",
  },
  {
    question: "Are languages other than English supported?",
    answer:
      "Yes! Any messages left in a language other than English will still be transcribed in that language, but additionally an English translation will be provided. If you would like messages translated to a language other than English, you can specify that on an indivdual email/mailbox basis.",
  },
  {
    question: "Will voice mails of any length be transcribed?",
    answer:
      "If the voice mail message is longer than 3 minutes, only the first 3 minutes will be transcribed.",
  },
  {
    question: "How do I access transcribed voice mail messages",
    answer:
      "Transcribed voice mail messages are delivered via email, and you would access them however you normally check your email messages.",
  },
  {
    question: "Can this service be used to transcribe recorded calls?",
    answer:
      "If our servers encounter audio in which 2 or more voices are present, they will still attempt to transcribe the audio; however, accuracy may be lower, and the service does not identify or separate speakers. For these reasons, this service should not be used for recordings with more than a single speaker. Additionally, only the first 3 minutes of a recording are transcribed.",
  },
  {
    question: "What happens to emails sent to vm2email.com without an attachment?",
    answer: "These messages will be discarded and will not be forwarded to the specified email address.",
  },
  {
    question: "Are there any advantages to paying yearly for a subscription versus monthly?",
    answer:
      "Yes! When you pay yearly, a 2-month discount is provided, so you receive a full year of service for the price of ten months.",
  },
  {
    question: "What is required technically to get setup with voice mail transcription for my organization?",
    answer:
      "If you’re using our free trial, all we need is your email address! You’ll receive an email from us that includes a special vm2email.com email address. By setting your phone system to forward voice mails to this special address, any received messages will be transcribed and sent to your regular email. If you sign up with one of our paid plans, you’ll be prompted to create an account with us. This will allow you to have multiple vm2email.com email addresses, one for each person in your organization for whom you would like to have transcription. You can manage these email addresses from your account, as well as update your billing information.",
  },
  {
    question: "Do transcribed voice mail messages include the original audio?",
    answer:
      "Yes. In addition to the transcription text being included at the top of the email body, the audio attachment sent to our servers is forwarded along with the email to its recipient.",
  },
  {
    question: "What AI-powered features are included in this service?",
    answer:
      "All plans include: (1) An automatic summary of the voicemail in the subject line of the email we send to you, including (as available) the Caller name/company/phone number, who they’re trying to reach, and the subject of the voice mail; (2) If the voice mail is in a language other than English, it will still be transcribed in that language, but additionally an English translation will be provided.",
  },
];

function AccordionItem({ heading, content, isOpen, toggleAccordion }) {
  return (
    <div className="accordion-item w-full">
      <h2 className="accordion-heading w-full">
        <button
          className="accordion-button flex items-start justify-between w-full"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleAccordion}
        >
          {heading}
          {isOpen ? (
            <img src={minus} alt="minus" className="w-[45.69px] md:w-[57.73px] h-[47px] md:h-[54.43px]" />
          ) : (
            <img src={plus} alt="plus" className="w-[45.69px] md:w-[57.73px] h-[47px] md:h-[54.43px]" />
          )}
        </button>
      </h2>
      <div
        className={`accordion-content ${
          isOpen ? "active" : ""
        } text-[13px] md:text-[15px] pt-1 pb-5 px-5 w-[90%] text-[#9A9696] font-custom`}
      >
        {content}
      </div>
    </div>
  );
}

const FAQs = () => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="bg-[#F8FDFE]">
      <Header />

      <div className="py-12 px-7 lg:px-10 xl:px-14 2xl:px-32">
        <div className="flex items-center justify-center border-b border-[#BFBFBF] pb-[20px] rounded-lg text-[22px] md:text-[40px] text-custom font-latoBold">
          Freq
          <span className="relative w-fit">
            uently Asked Q
            <img src={dotline} alt="dotline" className="absolute left-0 -bottom-1.5 w-full" />
          </span>
          uestions
        </div>

        <div className="max-w-7xl mx-auto mt-10 md:mt-12 mb-16 gap-5">
          {faqs.map((v, index) => (
            <div
              key={index}
              className={`flex items-center justify-between bg-[#FFFFFF] shadow-dropAccordian border border-[#b6e9ee] rounded-lg w-full mt-${
                index === 0 ? "0" : "3"
              }`}
            >
              <AccordionItem
                heading={
                  <div className="font-latoSemiBold text-custom text-[15.5px] lg:text-[16.5px] xl:text-[20px] capitalize py-3 px-5 text-start">
                    {v?.question}
                  </div>
                }
                content={<p className="border-l-2 border-[#67D329] pl-3">{v?.answer}</p>}
                isOpen={openAccordionIndex === index}
                toggleAccordion={() => toggleAccordion(index)}
              />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default FAQs;
