import React, { useEffect, useState } from "react";
import star from "../../Assets/star.png";
import ring from "../../Assets/ring.webp";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";
import bgQue from "../../Assets/question-bg.webp";
import questionImg from "../../Assets/question-img.webp";
import questionMark from "../../Assets/question.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";

const faqs = [
  {
    question: "How long does transcription take?",
    answer:
      "Transcription is fast. In most circumstances, an email containing the voice mail audio is routed through our servers, transcribed, and forwarded to your email in less than a minute.",
  },
  {
    question: "How accurate is voice mail transcription?",
    answer:
      "The accuracy of transcribed voice mail messages is dependent upon several factors, including the quality of the audio, how well the speaker enunciates, whether there are specialized words or phrases included that are uncommon in everyday speech, and whether a non-standard accent is present. Typical accuracy with our service is up to 95 percent or even greater, the highest in the industry. But feel free to take advantage of our free trial and see the quality for yourself!",
  },
  {
    question: "How do I access transcribed voice mail messages",
    answer:
      "Transcribed voice mail messages are delivered via email, and you would access them however you normally check your email messages.",
  },
  {
    question: "What AI-powered features are included in this service?",
    answer:
      "All plans include: (1) An automatic summary of the voicemail in the subject line of the email we send to you, including (as available) the Caller name/company/phone number, who they’re trying to reach, and the subject of the voice mail; (2) If the voice mail is in a language other than English, it will still be transcribed in that language, but additionally an English translation will be provided.",
  },
  {
    question: "Are languages other than English supported?",
    answer:
      "Yes! Any messages left in a language other than English will still be transcribed in that language, but additionally an English translation will be provided. If you would like messages translated to a language other than English, you can specify that on an indivdual email/mailbox basis.",
  },
];

function AccordionItem({ heading, content, isOpen, toggleAccordion }) {
  return (
    <div className="accordion-item w-full">
      <h2 className="accordion-heading w-full">
        <button
          className="accordion-button flex items-start justify-between w-full"
          aria-expanded={isOpen ? "true" : "false"}
          onClick={toggleAccordion}
        >
          {heading}
          {isOpen ? (
            <FaCaretUp className="text-[20px] text-[#58cf51] mt-0.5 flex items-center justify-end" />
          ) : (
            <FaCaretDown className="text-[20px] text-[#58cf51] mt-0.5 flex items-center justify-end" />
          )}
        </button>
      </h2>
      <div
        className={`accordion-content ${
          isOpen ? "active" : ""
        } ml-7 mt-1.5 text-[13px] md:text-[14px] pt-2.5 pb-1 w-[90%] text-[#6C797B] font-custom`}
      >
        {content}
      </div>
    </div>
  );
}

export default function Questions() {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const urlContainsFAQ = window.location.hash === "#faq";
    const faqElement = document.getElementById("faq");

    if (urlContainsFAQ && faqElement) {
      faqElement.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div id="faq">
      <div className="backgroundImage3 lg:h-[100vh] w-full md:mb-12 lg:mb-0">
        <div className="w-full flex justify-center pt-16 md:pt-24 lg:pt-40">
          <div className="relative w-fit">
            <div className="text-[22px] sm:text-[30px] md:text-[36px] 2xl:text-[44px] text-custom font-latoBold">
              Frequently{" "}
              <span className="relative">
                <span>Asked</span>
                <img
                  src={ring}
                  alt="ring"
                  className="absolute top-0 xl:top-1 -left-1 w-[100%] h-[30px] md:h-[50px]"
                />{" "}
              </span>
              Questions
            </div>
            <img src={star} alt="star" className="w-[26px] absolute -top-4 md:-top-2 -left-5 md:-left-3" />
          </div>
        </div>

        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-center mt-10 md:mt-20 gap-5 px-5 xl:px-0">
          <div>
            {faqs.map((v, index) => (
              <div
                key={index}
                className={`flex items-center justify-between bg-[#FFFFFF] shadow-dropAccordian border border-[#b6e9ee] py-3 px-5 rounded-lg w-full md:w-[400px] xl:w-[600px] mt-${
                  index === 0 ? "0" : "3"
                }`}
              >
                <AccordionItem
                  heading={
                    <div className="flex items-start gap-3">
                      <img src={questionMark} alt="questionMark" className="w-[17px] mt-1" />
                      <div className="font-latoBold text-custom text-[15.5px] lg:text-[16.5px] xl:text-[17px] text-start">
                        {v?.question}
                      </div>
                    </div>
                  }
                  content={<p className="border-l-2 border-[#67D329] pl-3">{v?.answer}</p>}
                  isOpen={openAccordionIndex === index}
                  toggleAccordion={() => toggleAccordion(index)}
                />
              </div>
            ))}

            <div className="w-full flex items-center justify-center md:justify-start mt-9 md:mt-5">
              <Link to="/faqs">
                <div className="flex items-center gap-2 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-6 rounded-xl w-fit cursor-pointer">
                  View All FAQs
                  <MdOutlineArrowForwardIos className="text-[15px]" />
                </div>
              </Link>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <img src={questionImg} alt="questionImg" className="w-[350px] lg:w-[500px]" />
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .backgroundImage3 {
            background-image: url(${bgQue});
            background-size: cover;
          }
        `}
      </style>
    </div>
  );
}
