import React, { useEffect, useState } from "react";
import underline from "../../Assets/underline.png";
import tick from "../../Assets/tick.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import bgPlan from "../../Assets/plan-bg.webp";
import { Link } from "react-router-dom";

const pricingArray = [
  {
    planType: "Basic",
    price: "7",
    yearlyPrice: "79",
    mailBoxes: "1",
    messageMonthly: "100",
    overage: "9"
  },
  {
    planType: "Standard",
    price: "14",
    yearlyPrice: "149",
    mailBoxes: "5",
    messageMonthly: "400",
    overage: "8"
  },
  {
    planType: "Plus",
    price: "24",
    yearlyPrice: "249",
    mailBoxes: "15",
    messageMonthly: "1,250",
    overage: "7"
  },
  {
    planType: "Advanced",
    price: "49",
    yearlyPrice: "499",
    mailBoxes: "40",
    messageMonthly: "2,500",
    overage: "6"
  },
  {
    planType: "Ultimate",
    price: "69",
    yearlyPrice: "699",
    mailBoxes: "80",
    messageMonthly: "5,000",
    overage: "5"
  },
]

export default function Pricing() {
  const [toggleMonthly, setToggleMonthly] = useState(false);

  useEffect(() => {
    const urlContainsPricing = window.location.hash === "#pricing";
    const pricingElement = document.getElementById("pricing");

    if (urlContainsPricing && pricingElement) {
      pricingElement.scrollIntoView({ behavior: "smooth" });
    }
    window.scrollTo(0, 0);

  }, []);

  return (
    <div id="pricing">
      <div className="backgroundImage2 xl:h-[100vh] px-6 pb-16 md:pb-6 xl:pb-0">
        <div className="py-11 lg:py-20">
          <div className="text-[22px] sm:text-[30px] md:text-[36px] 2xl:text-[44px] text-white hidden md:flex items-center justify-center font-latoBold">
            Pricing Plans For Ever
            <span className="relative w-fit">
              y Need <img src={underline} alt="underline" className="absolute -left-2 -bottom-1" />
            </span>
          </div>

          <div className="flex items-center justify-center md:hidden text-[22px] font-latoBold text-[#FFFFFF]">
            Pricing Plans For Every Need
          </div>

          <div className="text-[14px] text-[#949697] font-custom text-center mt-6">
            Looking to enable transcription for your entire <br className="flex md:hidden" /> phone system?{" "}
            <br className="hidden md:flex" />
            <Link to="/pricing-page" className="hover:text-blue-400 cursor-pointer">Click here for alternate plans.</Link>
          </div>

          <div className="toggle-container flex justify-center items-center mt-8 gap-4">
            <span
              className={`monthly ${toggleMonthly ? "opacity-50" : "opacity-100"
                } text-white font-custom text-[15px]`}
            >
              Monthly
            </span>

            <label className="switch">
              <input
                type="checkbox"
                checked={toggleMonthly}
                onChange={() => setToggleMonthly(!toggleMonthly)}
              />
              <span className="slider round"></span>
            </label>

            <span
              className={`yearly ${toggleMonthly ? "opacity-100" : "opacity-50"
                } text-white font-custom text-[15px]`}
            >
              Yearly
            </span>
          </div>

          {toggleMonthly ? (
            <div className="max-w-7xl mx-auto mt-7 lg:mt-16">
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <div>
                  <div className="font-latoMedium uppercase text-[21px] text-center lg:text-start text-white ml-2">
                    features
                  </div>

                  <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 w-[310px] rounded-l-lg mt-4 lg:mt-2.5">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      2 Months FREE Versus Monthly Plan
                    </div>
                  </div>

                  <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Setup Fee
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-5 lg:gap-1.5 xl:gap-2.5 mt-4">
                  {pricingArray.map((item, index) => {
                    return (<>
                      <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                        <div className="flex items-center justify-center -mt-3">
                          <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoMedium text-[13px] px-4">
                            {item.planType}
                          </div>
                        </div>

                        <div className="flex items-center justify-center mt-4">
                          <div className="text-[28px] text-white w-fit font-latoBold relative">
                            {item.yearlyPrice}
                            <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                          </div>
                        </div>

                        <div className="text-[12px] text-white text-center font-poppinsLight tracking-wide">
                          Billed Yearly
                        </div>

                        <div className="bg-[#153b40] h-[43px] mt-3 flex items-center justify-center">
                          <img src={tick} alt="tick" className="w-[15px]" />
                        </div>

                        <div className="flex items-center justify-center h-[43px]">
                          <div className="text-[14px] text-white font-latoBold">{item.mailBoxes}</div>
                        </div>

                        <div className="flex items-center justify-center bg-[#153b40] h-[43px]">
                          <div className="text-[14px] text-white font-latoBold">{item.messageMonthly}</div>
                        </div>

                        <div className="flex items-center justify-center h-[43px]">
                          <div className="text-[14px] text-white text-center font-latoBold">{item.overage} Cents</div>
                        </div>

                        <div className="flex items-center justify-center bg-[#153b40] h-[43px]">
                          <div className="text-[14px] text-white text-center font-latoBold">$0</div>
                        </div>

                        <div className="py-5 px-3 lg:px-1.5 xl:px-3">
                          <Link to='/orderSummary' state={{ ...item, yearly: true }}>
                            <div className={item.planType === "Plus" ? "flex items-center gap-1 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 rounded-[11px] cursor-pointer" : "flex items-center gap-1 font-latoMedium border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer"}>
                              Subscribe Now <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>)
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="max-w-7xl mx-auto mt-7 lg:mt-16">
              <div className="flex flex-col lg:flex-row items-center justify-center">
                <div>
                  <div className="font-latoMedium uppercase text-[21px] text-center lg:text-start text-white ml-2">
                    features
                  </div>

                  <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 w-[310px] rounded-l-lg mt-4 lg:mt-2.5">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="lg:bg-[#174146] flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="bg-transparent flex items-center gap-3 p-1.5 lg:p-3 rounded-l-lg">
                    <img src={tick} alt="tick" className="w-[15px]" />
                    <div className="text-white text-[14px] tracking-wide font-latoMedium -mt-0.5">
                      Setup Fee
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap lg:flex-nowrap items-center justify-center gap-5 lg:gap-2.5 mt-2.5">
                  {pricingArray.map((item, index) => {
                    return (<>
                      <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                        <div className="flex items-center justify-center -mt-3">
                          <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoMedium text-[13px] px-4">
                            {item.planType}
                          </div>
                        </div>

                        <div className="flex items-center justify-center mt-4">
                          <div className="text-[28px] text-white w-fit font-latoBold relative">
                            {item.price}<span className="text-[12px] absolute top-0.5 -left-2">$</span>
                          </div>
                        </div>

                        <div className="text-[12px] text-white text-center font-poppinsLight tracking-wide">
                          Billed Monthly
                        </div>

                        <div className="flex items-center justify-center bg-[#153b40] h-[43px] mt-4">
                          <div className="text-[14px] text-white font-latoBold">{item.mailBoxes}</div>
                        </div>

                        <div className="flex items-center justify-center h-[43px]">
                          <div className="text-[14px] text-white font-latoBold">{item.messageMonthly}</div>
                        </div>

                        <div className="flex items-center justify-center bg-[#153b40] h-[43px]">
                          <div className="text-[14px] text-white text-center font-latoBold">{item.overage} Cents</div>
                        </div>

                        <div className="flex items-center justify-center h-[43px]">
                          <div className="text-[14px] text-white text-center font-latoBold">$0</div>
                        </div>

                        <div className="py-5 px-3 lg:px-1.5 xl:px-3">
                          <Link to='/orderSummary' state={{ ...item, yearly: false }}>
                            <div className={item.planType === "Plus" ? "flex items-center gap-1 font-latoMedium bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 rounded-[11px] cursor-pointer" : "flex items-center gap-1 font-latoMedium border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer"}>
                              Subscribe Now <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>)
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <style jsx>
        {`
          .backgroundImage2 {
            background-image: url(${bgPlan});
            background-size: cover;
          }
        `}
      </style>
    </div>
  );
}
