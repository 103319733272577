import React from "react";
import Header from "./Header";
import dotline from "../Assets/dotline.png";
import inbox from "../Assets/inbox2.png";
import location from "../Assets/location.png";
import twitter from "../Assets/twitter1.png";
import instagram from "../Assets/instagram1.png";
import social from "../Assets/social.png";
import round from "../Assets/round.png";
import Footer from "./LandingPage/Footer";

const Contact = () => {
  return (
    <div className="bg-[#F8FDFE]">
      <Header />

      <div className="py-12 px-7 lg:px-10 xl:px-14 2xl:px-32">
        <div className="flex items-center justify-center text-[22px] md:text-[40px] text-custom font-latoBold">
          Ge
          <span className="relative w-fit">
            t In Tou
            <img src={dotline} alt="dotline" className="absolute left-0 -bottom-1.5" />
          </span>
          ch
        </div>

        <div className="text-[13px] md:text-[17px] font-custom text-[#9A9696] text-center mt-4">
          Any questions or support requests should be directed <br /> to 
          <a href="mailto:support@vm2email.com" className="text-[#26C0D2] underline cursor-pointer">
            support@vm2email.com.
          </a>
           For your convenience, you <br /> may alternatively fill out the form below.
        </div>

        <div className="bg-[#FFFFFF] border border-[#bfe9ee] shadow-dropContact rounded-2xl p-1.5 mt-14 mb-12">
          <div className="grid grid-rows-1">
            <div className="grid grid-cols-12 space-x-0 md:space-x-2 lg:space-x-8">
              <div className="col-span-12 lg:col-span-4 flex flex-col items-center md:items-start bg-[#0B373C] rounded-2xl px-7 pt-5 pb-7 md:py-7 relative mb-5">
                <div className="text-[22px] lg:text-[26px] text-[#FFFFFF] font-latoSemiBold">
                  Contact Information
                </div>

                <div className="mt-14 lg:mt-24">
                  <div className="flex flex-col md:flex-row items-center gap-5 md:gap-8">
                    <img src={inbox} alt="inbox" className="w-[25.75px] h-[21px]" />
                    <div className="text-[14px] md:text-[15px] lg:text-[17px] text-[#FFFFFF] font-custom underline">
                      support@vm2email.com
                    </div>
                  </div>

                  <div className="flex flex-col md:flex-row items-center gap-3.5 md:gap-8 mt-7 md:mt-8">
                    <img src={location} alt="location" className="w-[21px] h-[25.75px]" />
                    <div className="text-[14px] md:text-[15px] lg:text-[17px] text-[#FFFFFF] font-custom">
                      2729 S. Azalea Dr.
                      <br className="block md:hidden lg:block" />
                      Tempe, AZ 85282
                    </div>
                  </div>
                </div>

                {/* <div className="flex items-center gap-6 mt-28">
                    <img src={twitter} alt="twitter" className="w-[30px] h-[30px]" />
                    <img src={instagram} alt="instagram" className="w-[30px] h-[30px]" />
                    <img src={social} alt="social" className="w-[30px] h-[30px]" />
                  </div> */}

                <div></div>

                <img src={round} alt="round" className="absolute bottom-0 right-0 w-[100px] md:w-auto" />
              </div>

              <div className="col-span-12 lg:col-span-8 px-6 pt-4 md:pt-12 w-full lg:w-[95%]">
                <div className="flex flex-col sm:flex-row items-center gap-6 md:gap-14">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="border-b border-[#26C0D2] outline-none text-[16px] w-full text-custom placeholder:text-custom font-latoMedium pb-6"
                  />
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="border-b border-[#26C0D2] outline-none text-[16px] w-full text-custom placeholder:text-custom font-latoMedium pb-6"
                  />
                </div>

                <div className="flex flex-col sm:flex-row items-center gap-6 md:gap-14 mt-6 md:mt-9">
                  <input
                    type="text"
                    placeholder="Email"
                    className="border-b border-[#26C0D2] outline-none text-[16px] w-full text-custom placeholder:text-custom font-latoMedium pb-6"
                  />
                  <input
                    type="text"
                    placeholder="Phone Number"
                    className="border-b border-[#26C0D2] outline-none text-[16px] w-full text-custom placeholder:text-custom font-latoMedium pb-6"
                  />
                </div>

                <div className="flex flex-col sm:flex-row items-center gap-6 md:gap-14 mt-6 md:mt-9">
                  <input
                    type="text"
                    placeholder=" Subject"
                    className="border-b border-[#26C0D2] outline-none text-[16px] w-full text-custom placeholder:text-custom font-latoMedium pb-6"
                  />
                  <div className="w-full hidden sm:block"></div>
                </div>

                <div className="border border-[#26C0D2] mt-12 md:mt-6 py-1.5 px-4 rounded-lg">
                  <div className="text-[16px] text-custom font-latoMedium hidden md:block">Message</div>
                  <textarea
                    rows={7}
                    placeholder="Write your message..."
                    className="text-[14px] font-latoMedium outline-none mt-4 w-full"
                  ></textarea>
                </div>

                <div className="flex items-center justify-end py-5">
                  <div className="bg-gradient-to-r from-[#65D226] to-[#29C1D3] text-[#FFFFFF] text-[16px] text-center font-latoMedium w-full sm:w-fit py-2.5 px-10 rounded-lg">
                    Send Message
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
