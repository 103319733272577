import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./components/Login";
import LandingPage from "./components/LandingPage/LandingPage";
import OrderSummary from "./components/OrderSummary";
import EmailMapping from "./components/Profile/EmailMapping";
import Billing from "./components/Profile/Billing";
import Pricing from "./components/Profile/Pricing";
import Profile from "./components/Profile/Profile";
import Contact from "./components/Contact";
import FAQs from "./components/FAQs";
import ErrorPage from "./components/ErrorPage";
import PricingPage from "./components/PricingPage";
import Terms from "./components/Terms";

const RouterMain = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/orderSummary" element={<OrderSummary />} />
        <Route path="/emailMapping" element={<EmailMapping />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/pricing-page" element={<PricingPage />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default RouterMain;
