import React from "react";
import Header from "./Header";
import bg from "../Assets/error_bg.png";
import bgChecks from "../Assets/error_bg_blue_checks.png";
import error_robot from "../Assets/error_robot.png";

export default function ErrorPage() {
  return (
    <div>
      <Header />
      <div className="bg-no-repeat flex justify-center items-center bg-center md:min-h-[calc(100vh-95px)] min-h-[calc(100vh-65px)]" style={{ backgroundImage: `url(${bg}),url(${bgChecks})` }}>
        <div className="text-center">
          <img src={error_robot} className="sm:max-w-[400px] max-w-[250px]" alt="error_robot" />
          <h1 className="text-4xl font-bold mt-5">Page Not Found</h1>
          <h6 className="max-w-[350px] mt-3 text-gray-400">
            The page you are looking for might have been removed had its name changed or is temporarily unavailable.
          </h6>
          <button className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-7 mt-5 rounded-lg cursor-pointer" onClick={()=>window.location.href="/"}>Go Back</button>
        </div>
      </div>
    </div>
  );
}
