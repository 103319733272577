import React from "react";
import Sidebar from "./Sidebar";

const Profile = () => {
  return (
    <Sidebar>
      <div className="bg-[#FFFFFF] border border-[#CDE5E8] shadow-dropCard2 rounded-lg py-7 px-0 md:px-9 mt-8 md:mt-0">
        <div className="text-[20px] lg:text-[26px] text-custom font-latoSemiBold px-7">
          Account Information
        </div>

        <hr className="text-[#9a9a9a] mt-8" />

        <div className="px-7 py-6 md:py-14">
          <div className="flex gap-4 md:gap-11">
            <div>
              <div className="text-[14px] lg:text-[16px] text-[#333333] font-custom w-[100px] mt-2">
                Full Name <span className="text-[#FF0000]">*</span>
              </div>

              <div className="text-[14px] lg:text-[16px] text-[#333333] font-custom w-fit mt-14">
                Email <span className="text-[#FF0000]">*</span>
              </div>

              <div className="text-[14px] lg:text-[16px] text-[#333333] font-custom w-fit mt-12">
                Phone Number
              </div>

              <div className="text-[14px] lg:text-[16px] text-[#333333] font-custom w-fit mt-[50px]">
                Your Password <span className="text-[#FF0000]">*</span>
              </div>
            </div>

            <div>
              <input
                type="text"
                placeholder="John Smith"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full lg:w-[70%] outline-none shadow-dropInput rounded-md py-3 px-7"
              />

              <input
                type="text"
                placeholder="sunaina.techcave@gmail.com"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full lg:w-[70%] outline-none shadow-dropInput rounded-md py-3 px-7 mt-6"
              />

              <input
                type="text"
                placeholder="+92 3485647078"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full lg:w-[70%] outline-none shadow-dropInput rounded-md py-3 px-7 mt-6"
              />

              <input
                type="text"
                placeholder="************"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full lg:w-[70%] outline-none shadow-dropInput rounded-md py-3 px-7 mt-6"
              />
            </div>
          </div>
        </div>

        <hr className="text-[#9a9a9a]" />

        <div className="flex items-center justify-center lg:justify-end gap-8 mt-7">
          <div className="text-[#0B373C80] text-[16px] font-latoMedium border border-[#0B373C80] py-2 px-5 rounded-xl cursor-pointer">
            Cancel
          </div>

          <div className="text-[#FFFFFF] text-[16px] font-latoMedium w-fit bg-gradient-to-r from-[#4FCC6A] to-[#2DC2C8] py-2.5 px-6 rounded-xl cursor-pointer">
            Save change
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default Profile;
