import React from "react";
import star3 from "../../Assets/star2.png";
import ring from "../../Assets/ring.webp";
import bg from "../../Assets/features.webp";
import bg1 from "../../Assets/features1.webp";
import check from "../../Assets/check.png";
import { MdOutlineArrowForwardIos } from "react-icons/md";

export default function Features() {
  return (
    <>
      <div className="pt-10 md:pt-16 lg:pt-24 w-full">
        <div className="relative mx-auto max-w-7xl">
          <div className="w-full flex items-center justify-center">
            <div className="relative w-fit">
              <div className="text-[22px] sm:text-[30px] md:text-[36px] 2xl:text-[44px] text-custom font-latoSemiBold">
                Why{" "}
                <span className="relative">
                  <span>Choose</span>
                  <img src={ring} alt="ring" className="absolute top-0.5 -left-1 w-[100%] md:h-[55px]" />{" "}
                </span>
                vm2email?
              </div>
              <img src={star3} alt="star3" className="w-[26px] absolute -top-2 -left-5 md:-left-7" />
            </div>
          </div>

          <div className="hidden lg:flex items-center justify-center w-full -mt-24 xl:-mt-36">
            <img src={bg} alt="check" className="h-[520%] md:w-[1024px] lg:w-full" />
          </div>

          <div className="flex lg:hidden items-center justify-center w-full">
            <img src={bg1} alt="check" className="absolute -ml-10 -top-5" />
          </div>

          <div className="pt-48 lg:pt-0 px-7 sm:px-4 lg:px-0">
            <div className="lg:absolute top-28 xl:top-36 w-full flex flex-col sm:flex-row items-center justify-center sm:gap-6 md:gap-14 lg:gap-[24%] mt-14 lg:mt-0">
              <div className="relative text-[#646464] font-latoSemiBold text-[14px] bg-[#FFFFFF] border border-[#bdecf1] shadow-drop py-6 pl-3 lg:pl-5 pr-3 md:pr-6 lg:pr-8 text-start md:text-end w-full md:w-fit rounded-2xl h-[89px]">
                <span className="text-[14.5px] text-custom">Flexibility:</span> Enjoy month-to-month service{" "}
                <br className="flex sm:hidden md:flex" /> with the freedom to cancel anytime.
                <img src={check} alt="check" className="absolute top-1/3 -right-4 w-[30px] h-[30px]" />
              </div>

              <div className="relative text-[#646464] font-latoSemiBold text-[14px] bg-[#FFFFFF] border border-[#bdecf1] shadow-drop py-6 pl-3 md:pl-6 lg:pl-8 pr-3 lg:pr-5 mt-3 sm:mt-0 text-start w-full md:w-fit rounded-2xl h-[89px]">
                <span className="text-[14.5px] text-custom">No-Hassle Setup:</span> Get started in minutes,{" "}
                <br className="flex sm:hidden md:flex" /> with a minimum of effort.
                <img
                  src={check}
                  alt="check"
                  className="absolute top-1/3 -right-4 md:-left-4 w-[30px] h-[30px]"
                />
              </div>
            </div>

            <div className="lg:absolute top-64 xl:top-[305px] w-full flex flex-col sm:flex-row items-center justify-center sm:gap-6 md:gap-14 lg:gap-[32%] xl:gap-[35%] mt-3 md:mt-5 lg:mt-0">
              <div className="relative text-[#646464] font-latoSemiBold text-[14px] bg-[#FFFFFF] border border-[#bdecf1] shadow-drop py-3.5 pl-3 lg:pl-5 pr-3 md:pr-6 lg:pr-8 text-start md:text-end w-full md:w-fit rounded-2xl h-[89px]">
                <span className="text-[14.5px] text-custom">AI-Enhanced:</span> Enhanced email subjects{" "}
                <br className="flex sm:hidden md:flex" /> quickly reveal caller identity, number, and{" "}
                <br className="flex sm:hidden md:flex" /> call summary.
                <img src={check} alt="check" className="absolute top-1/3 -right-4 w-[30px] h-[30px]" />
              </div>

              <div className="relative text-[#646464] font-latoSemiBold text-[14px] bg-[#FFFFFF] border border-[#bdecf1] shadow-drop py-6 pl-3 md:pl-8 pr-3 md:pr-5 mt-3 sm:mt-0 text-start w-full md:w-fit rounded-2xl h-[89px]">
                <span className="text-[14.5px] text-custom">Discretion:</span> Check voicemails during{" "}
                <br className="flex sm:hidden md:flex" /> meetings or when unable to take calls.
                <img
                  src={check}
                  alt="check"
                  className="absolute top-1/3 -right-4 md:-left-4 w-[30px] h-[30px]"
                />
              </div>
            </div>

            <div className="lg:absolute top-[400px] xl:top-[470px] w-full flex flex-col sm:flex-row items-center justify-center sm:gap-6 md:gap-14 lg:gap-[32%] xl:gap-[30%] mt-3 md:mt-5 lg:mt-0">
              <div className="relative text-[#646464] font-latoSemiBold text-[14px] bg-[#FFFFFF] border border-[#bdecf1] shadow-drop py-3.5 pl-3 md:pl-8 lg:pl-5 pr-3 md:pr-8 text-start md:text-end w-full md:w-fit rounded-2xl h-[89px]">
                <span className="text-[14.5px] text-custom">Complimentary Trial:</span> Dive in risk-free{" "}
                <br className="flex sm:hidden md:flex" /> and experience the convenience <br /> firsthand.
                <img src={check} alt="check" className="absolute top-1/3 -right-4 w-[30px] h-[30px]" />
              </div>

              <div className="relative text-[#646464] font-latoSemiBold text-[14px] bg-[#FFFFFF] border border-[#bdecf1] shadow-drop py-3.5 pl-3 md:pl-6 lg:pl-8 pr-3 md:pr-5 mt-3 sm:mt-0 text-start w-full md:w-fit rounded-2xl h-[89px]">
                <span className="text-[14.5px] text-custom">Universal Compatibility:</span> If your <br />{" "}
                voicemail service can email an audio file, <br className="flex sm:hidden md:flex" /> you’re
                all set!
                <img
                  src={check}
                  alt="check"
                  className="absolute top-1/3 -right-4 md:-left-4 w-[30px] h-[30px]"
                />
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-center mt-10 mb-10 lg:mb-24 xl:mb-28 2xl:mb-30">
            <a href="#contact">
              <div className="flex items-center font-latoMedium gap-2 bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] text-[#FFFFFF] py-3 px-6 rounded-xl w-fit cursor-pointer">
                Get Started
                <MdOutlineArrowForwardIos className="text-[15px]" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
