import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import tick from "../../Assets/tick.png";
import tick1 from "../../Assets/tick1.png";

const Pricing = () => {
  const [toggleMonthly, setToggleMonthly] = useState(true);

  return (
    <Sidebar>
      <div className="bg-[#FFFFFF] border border-[#CDE5E8] shadow-dropCard2 rounded-lg py-7 px-4 lg:px-7 mt-8 md:mt-0">
        <div className="px-3 lg:px-6">
          <div className="text-[20px] lg:text-[26px] text-custom font-latoSemiBold">Subscription</div>
          <div className="text-[14px] text-[#26C0D2] font-custom">Your Current Subscription:</div>
        </div>

        <hr className="text-[#9a9a9a] mt-3.5" />

        <div className="px-3 lg:px-6 pt-2.5 pb-3">
          <div className="flex items-center gap-9">
            <div className="text-[16.5px] text-custom font-latoMedium">Name:</div>
            <div className="text-[16.5px] text-custom opacity-70 font-custom">Standard</div>
          </div>

          <div className="flex items-center gap-7 mt-2">
            <div className="text-[16.5px] text-custom font-latoMedium">Charge:</div>
            <div className="text-[16.5px] text-custom opacity-70 font-custom">$14.95 Monthly</div>
          </div>

          <div className="flex items-center gap-2 mt-2">
            <div className="text-[16.5px] text-custom font-latoMedium">Mailboxes:</div>
            <div className="text-[16.5px] text-custom opacity-70 font-custom">5</div>
          </div>
        </div>

        <hr className="text-[#9a9a9a]" />

        <div className="toggle-container flex justify-center mt-8 gap-2 sm:gap-6 lg:gap-10 px-3 lg:px-6">
          <span className="text-custom font-latoMedium text-[15px] sm:text-[18px] lg:text-[24px] leading-none">
            Monthly Plans
          </span>

          <label className="switch1 mt-0 md:mt-1.5">
            <input
              type="checkbox"
              checked={toggleMonthly}
              onChange={() => setToggleMonthly(!toggleMonthly)}
            />
            <span className="slider1 round"></span>
          </label>

          <span className="text-custom font-latoMedium text-[15px] sm:text-[18px] lg:text-[24px] leading-4 lg:leading-7">
            Yearly Plans <br />{" "}
            <span className="text-[15px] lg:text-[16px] text-[#9A9696] font-custom">(2 Months Free)</span>
          </span>
        </div>

        {toggleMonthly ? (
          <div className="mt-9 mb-10">
            <div className="flex flex-col lg:flex-row items-center justify-center">
              <div className="flex flex-wrap items-start justify-center gap-5 lg:gap-10 mt-2.5">
                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Basic <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      7.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      2 Months FREE Versus Monthly Plan
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <img src={tick1} alt="tick1" />
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">1</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">150</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">9 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Standard <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      14.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      2 Months FREE Versus Monthly Plan
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <img src={tick1} alt="tick1" />
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">5</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">400</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">8 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Plus <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      24.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      2 Months FREE Versus Monthly Plan
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <img src={tick1} alt="tick1" />
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">15</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">1250</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">7 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Advanced <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      49.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      2 Months FREE Versus Monthly Plan
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <img src={tick1} alt="tick1" />
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">40</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">2500</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">6 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Ultimate <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      69.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      2 Months FREE Versus Monthly Plan
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <img src={tick1} alt="tick1" />
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">80</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">5000</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">5 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-9 mb-10">
            <div className="flex flex-col lg:flex-row items-center justify-center">
              <div className="flex flex-wrap items-start justify-center gap-5 lg:gap-10 mt-2.5">
                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Basic <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      7.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">1</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">150</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">9 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Standard <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      14.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">5</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">400</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">8 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Plus <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      24.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">15</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">1250</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">7 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Advanced <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      49.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">40</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">2500</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">6 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>

                <div className="border border-[#14626b] bg-[#093136] rounded-xl mt-14 lg:mt-0">
                  <div className="flex items-center justify-center -mt-3">
                    <div className="bg-gradient-to-r from-[#58cf51] to-[#2cc2ca] w-fit rounded-full text-white font-latoSemiBold text-[13px] px-4 py-0.5">
                      Ultimate <span className="text-[9px] opacity-70">(Monthly)</span>
                    </div>
                  </div>

                  <div className="flex items-center justify-center mt-4">
                    <div className="text-[28px] text-white w-fit font-latoBold relative">
                      69.95
                      <span className="text-[12px] absolute top-0.5 -left-2">$</span>
                    </div>
                  </div>

                  <div className="text-[11.4px] text-[#CBDCD1] text-center font-latoMedium tracking-wide">
                    Billed Monthly
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] mt-4 px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Number of Mailboxes
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">80</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Messages Monthly Aggregate
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white font-latoBold">5000</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Per Message Overage
                    </div>
                  </div>

                  <div className="flex items-center justify-center h-[43px]">
                    <div className="text-[18px] text-white text-center font-latoBold">5 Cents</div>
                  </div>

                  <div className="flex items-center gap-3 bg-[#153b40] h-[43px] px-6">
                    <img src={tick} alt="tick" className="w-[17px] h-[17px]" />
                    <div className="text-[13.5px] text-white text-center font-latoSemiBold">
                      Foreign Language Translation
                    </div>
                  </div>

                  <div className="py-7 px-3 lg:px-1.5 xl:px-3 flex items-center justify-center">
                    <div className="flex items-center gap-1 font-latoMedium w-fit border border-[#42c98e] rounded-[11px] text-[14px] lg:text-[12px] xl:text-[14px] text-white py-2 px-4 lg:px-2 xl:px-4 cursor-pointer">
                      Switch Plan <MdOutlineArrowForwardIos className="gradient-text text-[12.5px]" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Sidebar>
  );
};

export default Pricing;
