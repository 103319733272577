import React from "react";
import Sidebar from "./Sidebar";

export default function Billing() {
  return (
    <Sidebar>
      <div className="bg-[#FFFFFF] border border-[#CDE5E8] shadow-dropCard2 rounded-lg py-7 mt-8 md:mt-0">
        <div className="px-6 md:px-9">
          <div className="text-[20px] lg:text-[26px] text-custom font-latoSemiBold">Billing Address</div>
        </div>

        <hr className="text-[#9a9a9a] mt-8" />

        <div className="px-6 md:px-9 py-7 w-full xl:w-[90%]">
          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-11">
            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                Street <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="123 Sesame St."
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>

            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                City <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="Gilgit"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-11 mt-4">
            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                State <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="Pakistan"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>

            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                Zip <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="15100"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>
          </div>
        </div>

        <hr className="text-[#9a9a9a] mt-8" />

        <div className="px-6 md:px-9 py-[18px]">
          <div className="text-[20px] lg:text-[26px] text-custom font-latoSemiBold">Payment Information</div>
          <div className="text-[13px] sm:text-[14.5] text-[#9A9696] font-custom mt-3">
            Current credit card ending in 1234, expiration 12/2025
          </div>
        </div>

        <hr className="text-[#9a9a9a]" />

        <div className="px-6 md:px-9 py-7 w-full xl:w-[90%]">
          <div className="w-full">
            <div className="text-[14px] text-[#333333] font-custom w-fit">Card number</div>

            <input
              type="text"
              placeholder="Card number"
              className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
            />
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-11 mt-4">
            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                Date <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="MM/YY"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>

            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                CVV <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="CVV"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-11 mt-4">
            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                Street <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="123 Sesame St."
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>

            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                City <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="Gilgit"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>
          </div>

          <div className="mt-4">
            <div className="text-[14px] text-[#333333] font-custom w-fit">Card number</div>

            <form className="mt-1">
              <select
                id="countries"
                className="text-[16px] text-[#9A9696] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-6 cursor-pointer"
              >
                {/* <option selected>Choose a country</option> */}
                <option selected>Pakistan</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
              </select>
            </form>
          </div>

          <div className="flex flex-col md:flex-row items-center gap-4 md:gap-11 mt-4">
            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                State <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="123 Sesame St."
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>

            <div className="w-full">
              <div className="text-[14px] text-[#333333] font-custom w-fit">
                Zip <span className="text-[#FF0000]">*</span>
              </div>

              <input
                type="text"
                placeholder="Gilgit"
                className="text-[16px] font-custom border border-[#A7B0B1] w-full outline-none shadow-dropInput rounded-md py-3 px-7 mt-1"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-center mt-6 lg:mt-14 mb-8 lg:mb-28">
          <div className="text-[#FFFFFF] text-[16px] font-custom w-fit bg-gradient-to-r from-[#4FCC6A] to-[#2DC2C8] py-3 px-14 rounded-xl cursor-pointer">
            Update
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
