import React from "react";
import Sidebar from "./Sidebar";
import setting from "../../Assets/setting.png";
import voicemail from "../../Assets/voicemail.png";
import select from "../../Assets/select.png";
import inbox1 from "../../Assets/inbox1.png";

export default function EmailMapping() {
  return (
    <Sidebar>
      <div className="bg-[#FFFFFF] border border-[#CDE5E8] shadow-dropCard2 rounded-lg py-7 mt-8 md:mt-0">
        <div className="px-6 lg:px-14">
          <div className="text-[20px] lg:text-[26px] text-custom font-latoSemiBold">
            Email Address Mapping:
          </div>

          <div className="text-[20px] lg:text-[26px] text-[#26C0D2] font-latoSemiBold border-b border-[#26C0D2] w-fit mt-8">
            Step 1
          </div>

          <div className="text-[18px] lg:text-[24px] text-[#000000] font-latoMedium text-center mt-6">
            Choose Destination
          </div>
        </div>

        <hr className="text-[#9a9a9a] mt-2 mb-[21px]" />

        <div className="px-6 lg:px-14">
          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-5">
            <div className="text-[18px] text-[#333333] font-custom border-b border-[#333333] w-fit">
              sample1@vm2email.com
            </div>

            <input
              type="text"
              placeholder="Destination Email Address"
              className="text-[16px] font-custom border border-[#A7B0B1] w-full md:w-[50%] outline-none shadow-dropInput rounded-md py-3 px-5"
            />
          </div>

          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-5 mt-4">
            <div className="text-[18px] text-[#333333] font-custom border-b border-[#333333] w-fit">
              sample2@vm2email.com
            </div>

            <input
              type="text"
              placeholder="Destination Email Address"
              className="text-[16px] font-custom border border-[#A7B0B1] w-full md:w-[50%] outline-none shadow-dropInput rounded-md py-3 px-5"
            />
          </div>

          <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-5 mt-4">
            <div className="text-[18px] text-[#333333] font-custom border-b border-[#333333] w-fit">
              sample3@vm2email.com
            </div>

            <input
              type="text"
              placeholder="Destination Email Address"
              className="text-[16px] font-custom border border-[#A7B0B1] w-full md:w-[50%] outline-none shadow-dropInput rounded-md py-3 px-5"
            />
          </div>

          <div className="text-[20px] lg:text-[26px] text-[#26C0D2] font-latoSemiBold border-b border-[#26C0D2] w-fit mt-[33px]">
            Step 2
          </div>

          <div className="text-[22px] lg:text-[24px] text-[#000000] font-latoMedium text-center mt-6">
            Configure phone Settings
          </div>

          <div className="text-[16px] lg:text-[17px] text-[#9A9696] font-custom text-center mt-2.5">
            Note: the specific instructions for setting up your voicemail{" "}
            <br className="hidden sm:flex md:hidden lg:flex" /> to email functionality will vary depending on
            your particular <br className="hidden sm:flex md:hidden lg:flex" /> system
          </div>

          <div className="flex gap-5 sm:gap-20 mt-10">
            <div>
              <div className="text-[15px] sm:text-[20px] md:text-[16px] lg:text-[20px] text-custom font-custom">
                1. Open Your Phone's Settings.
              </div>
              <div className="text-[15px] sm:text-[20px] md:text-[16px] lg:text-[20px] text-custom font-custom mt-5">
                2. Go To Voicemail Settings.
              </div>
              <div className="text-[15px] sm:text-[20px] md:text-[16px] lg:text-[20px] text-custom font-custom mt-5">
                3. Select 'Forward To Email'.
              </div>
              <div className="text-[15px] sm:text-[20px] md:text-[16px] lg:text-[20px] text-custom font-custom mt-5">
                4. Enter A Vm2email Address From <br /> Above As The Delivery Address
              </div>
            </div>

            <div>
              <img src={setting} alt="setting" className="w-[21px] h-[21px] mt-1 sm:mt-2.5" />
              <img src={voicemail} alt="voicemail" className="w-[21px] h-[9px] mt-7 sm:mt-8" />
              <img src={select} alt="select" className="w-[17px] h-[17px] mt-7 sm:mt-9" />
              <img src={inbox1} alt="inbox1" className="w-[21px] h-[15px] mt-7 sm:mt-[35px]" />
            </div>
          </div>
        </div>

        <hr className="text-[#9a9a9a] mt-8 mb-[21px]" />

        <div className="flex items-center justify-center mt-6 mb-8">
          <div className="text-[#FFFFFF] text-[16px] font-custom w-fit bg-gradient-to-r from-[#2DC2C8] to-[#4FCC6A] py-3 px-7 rounded-xl cursor-pointer">
            Update settings
          </div>
        </div>
      </div>
    </Sidebar>
  );
}
