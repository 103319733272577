import React, { useEffect, useState } from "react";
import circle from "../Assets/circle.webp";
import logo from "../Assets/logo.webp";
import login from "../Assets/login.webp";
import login1 from "../Assets/login1.webp";
import star from "../Assets/star.png";
import bg from "../Assets/login_bg.webp";
import line from "../Assets/line1.png";
import { FaStarOfLife } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleLogin = () => {
    if (!emailRegex.test(email) && !password.trim()) {
      setEmailError("Please enter a valid email address");
      setPasswordError("Please enter a password");
      return;
    } else {
      setEmailError("");
      setPasswordError("");
    }

    // Validate email
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    // Validate password
    if (!password.trim()) {
      setPasswordError("Please enter a password");
      return;
    } else {
      setPasswordError("");
    }

    // Save email to local storage if Remember Me is checked
    if (rememberMe) {
      localStorage.setItem("loginData", email);
    } else {
      localStorage.removeItem("loginData");
    }

    navigate("/");
  };

  useEffect(() => {
    const loginData = localStorage.getItem("loginData");
    if (loginData) {
      setEmail(loginData);
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-[#F8FDFE]">
      <div className="absolute top-0 right-0 w-full">
        <div className="relative flex items-center justify-end p-8 lg:p-12">
          <div className="relative z-[99]">
            <img
              src={logo}
              alt="logo"
              className="w-[70px] h-[35px] lg:w-[135px] lg:h-[64px] cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <img src={circle} alt="circle" className="absolute top-0 right-0 w-[202px] lg:w-[300px] z-0" />
        </div>
      </div>

      <div className="grid grid-rows-1 max-w-7xl mx-auto md:px-3">
        <div className="grid grid-cols-12 md:space-x-6">
          <div className="col-span-5 hidden md:block">
            <img src={login} alt="login" className="h-auto md:h-[610px] lg:h-[675px] xl:h-auto" />
          </div>

          <div className="col-span-12 md:col-span-7 backgroundImage flex flex-col items-start justify-center mt-56 md:mt-0">
            <div className="relative w-fit">
              <div className="text-[33px] xl:text-[44px] font-latoBold text-custom">Log In</div>
              <img src={star} alt="star" className="hidden md:block w-6 h-6 absolute -top-5 -right-1.5" />
            </div>

            <div className="text-[15px] font-custom text-[#707070] flex gap-1 mt-3">
              <span className="w-fit relative">
                Your <img src={line} alt="line" className="absolute bottom-0" />
              </span>{" "}
              Details
            </div>

            <div className="flex gap-1.5 mt-10">
              <div className="text-[14px] xl:text-[18px] font-custom text-[#333333]">Email</div>
              <FaStarOfLife className="text-[7px] text-[#FF0000] mt-1.5" />
            </div>

            <input
              type="text"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="border border-[#2FC3C2] outline-none mt-2 py-4 xl:py-5 px-7 shadow-dropBox2 rounded-lg w-full text-[15px] font-custom bg-[#FFFFFF]"
            />
            {emailError && <div className="text-red-500">{emailError}</div>}

            <div className="flex gap-1.5 mt-7">
              <div className="text-[14px] xl:text-[18px] font-custom text-[#333333]">Password</div>
              <FaStarOfLife className="text-[7px] text-[#FF0000] mt-1.5" />
            </div>

            <input
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border border-[#2FC3C2] outline-none mt-2 py-4 xl:py-5 px-7 shadow-dropBox2 rounded-lg w-full text-[15px] font-custom bg-[#FFFFFF]"
            />
            {passwordError && <div className="text-red-500">{passwordError}</div>}

            <div className="flex items-center justify-end w-full mt-3.5">
              <div className="text-[12px] xl:text-[14px] font-latoMedium w-fit gradient-text text-end cursor-pointer">
                Forgot Password?
              </div>
            </div>

            <div className="flex items-center gap-2 mt-4">
              <input
                type="checkbox"
                id="rm"
                name="rm"
                value="1"
                className="w-[21px] h-[20px] border border-[#949697] cursor-pointer"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label htmlFor="rm" className="text-[12px] font-latoMedium text-[#000000]">
                Remember Me
              </label>
            </div>

            <div
              className="bg-gradient-to-r from-[#58CF53] to-[#2EC3C4] text-[20px] py-3.5 xl:py-4 rounded-full w-full font-latoMedium text-[#FFFFFF] text-center mt-11 cursor-pointer"
              onClick={handleLogin}
            >
              Log In
            </div>
          </div>

          <div className="col-span-12 block md:hidden pt-11 pb-7">
            <img src={login1} alt="login1" />
          </div>
        </div>
      </div>

      <style>
        {`
          .backgroundImage {
            background-image: url(${bg});
            background-size: cover;
          }

          @media screen and (max-width: 768px) {
            backgroundImage {
              background-image: none !important;  
            }
          }
        `}
      </style>
    </div>
  );
}
